import React, { useState } from "react";
import "./PublishPopup.css";
import { IoCloseOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const PublishPopup = ({ setPublishPopup , publishData , submitForPublish }) => {
  const [publishRecipe, setPublishRecipe] = useState(false);

  const navigate = useNavigate();

  console.log("publishData", publishData);

  const publishRecipeData = () => {
    // Convert publishData to a JSON string and store it in sessionStorage
    const existingRecipes = JSON.parse(sessionStorage.getItem("recipes")) || [];
    const updatedRecipes = [...existingRecipes, publishData];
    sessionStorage.setItem("recipes", JSON.stringify(updatedRecipes));


    submitForPublish()

    console.log("updatedRecipes" , updatedRecipes)
    // Close the popup after publishing
    setPublishPopup(false);

    toast.success("Published Successfully", {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      bodyClassName: "single__line__toast",
    });
    navigate("/dashboard");
  };

  return (
    <div className="publish__popup">
      <div className="close__btn" onClick={() => setPublishPopup(false)}>
        <IoCloseOutline className="icons" />
      </div>
      <div className="publishrecipe__title">
        <div className="title xtra__large">Publishing Recipe</div>
        <div className="content small">
          Your recipe is almost ready for publishing! Please make sure to
          confirm the final review below before publishing your recipe.
        </div>
      </div>
      <div className="gpt__content__review">
        {/*  Ready for publish */}
        {publishRecipe ? (
          <>
            <div className="medium">Ready to publish!</div>
            <div className="small congrats__msg">
              Congrats! You’ve completed the recipe finalization checklist.
              Please select how you would like to publish.
            </div>
            <div className="publish__btn__section">
              <div className="cursor__texts_pink" onClick={publishRecipeData}>My Vault</div>
              <div className="cursor__texts_pink" onClick={publishRecipeData}>Private Community</div>
              <div className="cursor__texts_pink" onClick={publishRecipeData}>LeCuckoo Community</div>
            </div>
          </>
        ) : (
          <>
            <div className="gpt__title small">Final GPT Recipe Review</div>
            <div className="validdation__texts small">
              <div>
                Your recipe looks ready to go! Here are the items that have been
                reviewed:
              </div>
              <ul className="validations__lst">
                <li>Spell check complete</li>
                <li>Ingredients order complete</li>
                <li>GPT Recipe Review Accepted</li>
                <li>Recipe Testing Complete</li>
              </ul>
            </div>
          </>
        )}
      </div>

      {!publishRecipe && (
        <div className="confirm__publish">
          <div className="small">Are you ready to publish your recipe?</div>
          <div className="confirm__options"> 
              <div
                className="small cursor__texts_pink cursor__texts_blue"
                onClick={() => setPublishPopup(false)}
              >
                Edit
              </div>
              <div
                className="small cursor__texts_pink"
                onClick={() => setPublishRecipe(!publishRecipe)}
              >
                Confirm
              </div>
          </div>
          

        </div>
      )}
    </div>
  );
};
