const pdfRecipeData = [
  [
    {
      stage: "InProgress",
      id: 1715356252752,
      recipeName: "Scotcheroos",
      recipeDescription:
        "Our favorite quick no-bake treat is these delicious Scotcheroos made with the unbeatable flavor combination of peanut butter, chocolate, and butterscotch.",
      versionNumber: "10",
      flag:"isPdf",
      status: "Not Published",
      servingSize: 24,
      pdfUrl:"Recipe1.pdf",
      realpdfUrl: "https://tastesbetterfromscratch.com/wprm_print/scotcheroos",
      prepTime: "10 mins",
      cookTime: "5 mins",
      totalCookTime: "15 mins",
      ingredients: [
        {
          type: "header",
          name: "Ingredients",
        },
        {
          type: "ingredient",
          name: "crispy rice cereal (like Rice Krispies)",
          amount: 6,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "granulated sugar",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "light corn syrup*",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "creamy peanut butter",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "semi-sweet chocolate chips, good quality like Ghirardelli or Guittard*",
          amount: 12,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "butterscotch chips",
          amount: 12,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "Grease",
          amount: 2,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "Chocolate Layer",
          amount: 2,
          unit: "g",
        },
      ],
      directions: [
        {
          type: "direction",
          name: "Grease a 9x13' inch baking pan or line with parchment paper.",
        },
        {
          type: "direction",
          name: "Rice Krispies Layer: Place cereal in a large mixing bowl. Set aside. In a large saucepan over medium heat, combine the sugar and corn syrup. Bring to a low boil and then immediately remove from heat. Stir in peanut butter until smooth. Pour mixture over the cereal and stir to evenly coat. Press firmly into prepared pan.",
        },
        {
          type: "direction",
          name: "Add the chocolate chips and butterscotch chips to a microwave safe bowl. Microwave on low power for about 2 minutes, stirring every 30 seconds, until melted and smooth. Spread over rice krispies layer and allow to cool completely before cutting and serving.",
        },
      ],
      personalNote: [
        "Corn Syrup: Substitute honey if you'd like to make scotcheroos without corn syrup.",
        "Chocolate Chips: High quality chocolate is essential or the chocolate won't melt well. Use Ghiradelli or Guittard, or use Callaubut chocolate, or Bakers chocolate bars.",
        "To Make Ahead: Scotcheroos taste best within the first day or two they are made. Store them in an airtight container at room temperature.",
        "To Freeze: Cover well and freeze for up to 3 months. Thaw completely at room temperature before cutting and serving.",
      ],
      nutritionalFacts: {
        calories: 292,
        carbohydrates: 49,
        protein: 4,
        fat: 9,
        saturatedFat: 4,
        cholesterol: 2,
        sodium: 160,
        potassium: 161,
        fiber: 1,
        sugar: 37,
        vitaminA: 485,
        vitaminC: 4.5,
        calcium: 15,
        iron: 3.2,
      },
    },
  ],
  [
    {
      stage: "InProgress",
      id: 1711352262772,
      recipeName: "Lemon Bars",
      recipeDescription:
        "This EASY Lemon Bars recipe has just the right ratio of buttery shortbread crust and creamy lemon curd filling. They are made with fresh ingredients and always a crowd favorite!",
      versionNumber: "10",
      flag:"isPdf",
      status: "Not Published",
      servingSize: 9,
      prepTime: "15 mins",
      pdfUrl: "Recipe2.pdf",
      realpdfUrl: "https://tastesbetterfromscratch.com/wprm_print/lemon-bars",
      cookTime: "30 mins",
      totalCookTime: "45 mins",
      ingredients: [
        {
          type: "header",
          name: "Shortbread Crust",
        },
        {
          type: "ingredient",
          name: "butter, room temp",
          amount: 0.4,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "powdered sugar",
          amount: 0.3,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "all-purpose flour",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "salt",
          amount: 2,
          unit: "g",
        },
        {
          type: "header",
          name: "Lemon Filling",
        },
        {
          type: "ingredient",
          name: "large lemons, zested",
          amount: 3,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "granulated sugar",
          amount: 2,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "fresh lemon juice",
          amount: 0.3,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "all-purpose flour",
          amount: 0.4,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "baking powder",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "large eggs",
          amount: 4,
          unit: "g",
        },
      ],
      directions: [
        {
          type: "header",
          name: "Preheat oven",
        },
        {
          type: "direction",
          name: 'to 350 degrees F. Line the bottom of an 8" pan with parchment paper, or grease with cooking spray and set aside.',
        },
        {
          type: "header",
          name: "Shortbread Crust",
        },
        {
          type: "direction",
          name: "In a mixing bowl or the bowl of a stand mixer, cream together the butter and powdered sugar. Add the flour and salt and mix on low speed, just until combined.",
        },
        {
          type: "header",
          name: "Bake Crust",
        },
        {
          type: "direction",
          name: "Press the dough evenly into the bottom of the prepared dish. Bake for 10 minutes. Remove from oven and set aside.",
        },
        {
          type: "header",
          name: "Lemon Filling",
        },
        {
          type: "direction",
          name: "Mix lemon zest and sugar in a mixing bowl. Add the lemon juice, flour, baking powder, and eggs. Mix until well combined and the sugar is dissolved. Pour mixture over the baked crust.",
        },
        {
          type: "header",
          name: "Bake lemon bars",
        },
        {
          type: "direction",
          name: "at 350 degrees F for 35-40 more minutes, or until the filling has set. Remove from oven and allow to cool completely.",
        },
        {
          type: "header",
          name: "Dust with powdered sugar",
        },
        {
          type: "direction",
          name: "before cutting and serving. Store lemon bars in the refrigerator.",
        },
      ],
      personalNote: [
        "Make Ahead Instructions: Make this old fashioned lemon squares recipe up to 2 days in advance. Allow to cool completely before covering and storing in the refrigerator.",
        "Freezing Instructions: Allow lemon squares to cool completely before covering and freezing for up to 3 months. To make in a 9x13 inch pan, double the recipe",
      ],
      nutritionalFacts: {
        calories: 383,
        carbohydrates: 65,
        protein: 0,
        fat: 13,
        saturatedFat: 7,
        polyunsaturatedFat: 1,
        monounsaturatedFat: 3,
        transFat: 0.4,
        cholesterol: 110,
        sodium: 161,
        potassium: 97,
        fiber: 1,
        sugar: 49,
        vitaminA: 441,
        vitaminC: 16,
        calcium: 52,
        iron: 1,
      },
    },
  ],
  [
    {
      stage: "InProgress",
      id: 1211352568772,
      recipeName: "Gochujang Chicken Wings",
      recipeDescription:
        "This baking powder coating will guarantee you crispy chicken wings with no deep frying required. The sauce is spicy yet a bit savory, sweet, and sticky",
      versionNumber: "10",
      status: "Not Published",
      flag:"isPdf",
      servingSize: 4,
      prepTime: "15 mins",
      pdfUrl: "Recipe3.pdf",
      realpdfUrl: "https://www.allrecipes.com/recipe/8544435/gochujang-chicken-wings/?print",
      cookTime: "30 mins",
      totalCookTime: "50 mins",
      ingredients: [
        {
          type: "header",
          name: "Coating:",
        },
        {
          type: "ingredient",
          name: "baking powder",
          amount: 2,
          unit: "g",
        },
        {
          type: "ingredient",
          name: " freshly ground black pepper",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: " garlic powder",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "onion powder",
          amount: 0.5,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "chicken wings, separated at the joint",
          amount: 2,
          unit: "g",
        },
        {
          type: "header",
          name: "Sauce",
        },
        {
          type: "ingredient",
          name: " gochujang (Korean hot and sweet pepper paste), or to taste",
          amount: 3,
          unit: "g",
        },
        {
          type: "ingredient",
          name: " reduced-sodium soy sauce",
          amount: 2,
          unit: "g",
        },
        {
          type: "ingredient",
          name: " honey",
          amount: 0.3,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "rice vinegar",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "sesame oil",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "ground ginger",
          amount: 1,
          unit: "g",
        },
      ],
      directions: [
        {
          type: "header",
          name: "Step 1",
        },
        {
          type: "direction",
          name: 'Preheat the oven to 425 degrees F (220 degrees C). Line a large baking sheet with foil. Top with a wire rack which has been lightly sprayed with non-stick cooking spray; set aside.',
        },
        {
          type: "header",
          name: "Step 2",
        },
        {
          type: "direction",
          name: "Whisk baking powder, salt, pepper, garlic powder, and onion powder together in a small bowl. Add mixture to a large resealable plastic bag.",
        },
        {
          type: "header",
          name: "Step 3",
        },
        {
          type: "direction",
          name: "Pat the wings dry with a paper towel, and add some to the bag, shaking and tossing until coated. Shake off excess mixture and set aside. Repeat this procedure with the remaining wings. Place wings on the rack being sure not to overcrowd.",
        },
        {
          type: "header",
          name: "Step 4",
        },
        {
          type: "direction",
          name: "Move the baking sheet to the oven and cook for 20 minutes. Flip the wings, and bake until browned and crispy, about 15 minutes more. An instant-read thermometer inserted into the center should read at least 165 degrees F (74 degrees C). If you want your wings extra crispy, place under the broiler for a couple of minutes. You want them cooked through but still moist, so keep an eye on them to be sure they don't burn.",
        },
        {
          type: "header",
          name: "Step 5",
        },
        {
          type: "direction",
          name: "While the wings are baking, whisk gochujang, soy sauce, honey, rice vinegar, sesame oil, and ginger powder in a large bowl. When chicken wings are done cooking, immediately add them to the bowl, tossing until all are coated.",
        },
        {
          type: "header",
          name: "Step 6",
        },
        {
          type: "direction",
          name: "Remove to a serving platter, and garnish with green onions and sesame seeds.",
        },
      ],
      personalNote: [
        "Gochujang has many variations with different levels of spiciness. I use the hot and sweet sauce version which is still spicy. Whatever you use, just be sure to taste it and adjust the amount of gochujang to your taste preference: Your cooking time may vary depending on the size of your wings. I usually allow 35 minutes for medium size wings.",
      ],
      nutritionalFacts: {
        calories: 589,
        carbohydrates: 17,
        protein: 43,
        fat: 38,
        saturatedFat: 7,
        polyunsaturatedFat: 1,
        monounsaturatedFat: 3,
        transFat: 0.4,
        cholesterol: 175,
        sodium: 1748,
        potassium: 413,
        fiber: 1,
        sugar: 49,
        vitaminA: 441,
        vitaminC: 16,
        calcium: 52,
        iron: 1,
      },
    },
  ],
];

export default pdfRecipeData;
