import {convertValues}from  './FoodNetworkFormator';
function extractMinutes(duration) {
    console.log("duration", duration);
    // Create a regular expression to match the minutes part of the duration
    
    // if(duration == undefined) return "";

    const regex = /PT(\d+)M/;
    const match = duration.match(regex);

    // If the match is found, return the number of minutes as an integer
    if (match) {
        return parseInt(match[1], 10);
    }

    // If the match is not found, return null or an appropriate value indicating the failure
    return null;
}

function extractNutritionFacts(table) {
    const nutritionFacts = {
        calories: '',
        carbohydrates: '',
        protein: '',
        fat: '',
        saturatedFat: '',
        cholesterol: '',
        sodium: '',
        potassium: '',
        fiber: '',
        sugar: '',
        vitaminA: '',
        vitaminC: '',
        calcium: '',
        iron: ''
    };

    // Using DOM to get the table
    // const table = document.querySelector('.mntl-nutrition-facts-label__table');
    const rows = table.querySelectorAll('tbody tr');

    rows.forEach(row => {
        const nutrientName = row.querySelector('.mm-recipes-nutrition-facts-label__nutrient-name');
        const nutrientValue = row.querySelector('td:nth-child(2)');
        
        if (nutrientName) {
            const nameText = nutrientName.textContent.trim();
            const valueText = nutrientValue ? nutrientValue.previousElementSibling.textContent.trim() : '';

            switch (nameText) {
                case 'Total Fat':
                    nutritionFacts.fat = valueText.split('\n')[1];
                    break;
                case 'Saturated Fat':
                    nutritionFacts.saturatedFat = valueText.split('\n')[1];;
                    break;
                case 'Cholesterol':
                    nutritionFacts.cholesterol = valueText.split('\n')[1];;
                    break;
                case 'Sodium':
                    nutritionFacts.sodium = valueText.split('\n')[1];;
                    break;
                case 'Total Carbohydrate':
                    nutritionFacts.carbohydrates = valueText.split('\n')[1];;
                    break;
                case 'Dietary Fiber':
                    nutritionFacts.fiber = valueText.split('\n')[1];;
                    break;
                case 'Total Sugars':
                    nutritionFacts.sugar = nutrientName.parentElement.textContent.split('Total Sugars')[1].trim();
                    break;
                case 'Protein':
                    nutritionFacts.protein = valueText.split('\n')[1];;
                    break;
                case 'Vitamin C':
                    nutritionFacts.vitaminC = valueText.split('\n')[1];;
                    break;
                case 'Calcium':
                    nutritionFacts.calcium = valueText.split('\n')[1];;
                    break;
                case 'Iron':
                    nutritionFacts.iron = valueText.split('\n')[1];;
                    break;
                case 'Potassium':
                    nutritionFacts.potassium = valueText.split('\n')[1];;
                    break;
                default:
                    break;
            }
        } else if (row.classList.contains('mntl-nutrition-facts-label__calories')) {
            nutritionFacts.calories = row.querySelector('span:nth-child(2)').textContent.trim().split('\n')[1];;
        }
    });


    return nutritionFacts;
}


export function allReceipeDomainFormater(response, url) {
    let formatedReceipe = {};
    let receipeDetails = {};
    // console.log("ReceipeFormator", response.data);

      const parsedData = new DOMParser().parseFromString(response.data, 'text/html');
      const nutritionalTable = parsedData.querySelector('.mm-recipes-nutrition-facts-label__table');
    //   console.log("table",nutritionalTable);

    //   console.log("Extracted data", extractNutritionFacts(nutritionalTable));
   
      const scriptTag = parsedData.getElementById('allrecipes-schema_1-0');
      if (scriptTag) {
        // Get the JSON content inside the script tag
        const jsonContent = JSON.parse(scriptTag.textContent);
        // Extract specific elements from the JSON
        const recipe = jsonContent[0];
        console.log("source receipe",recipe);
        receipeDetails["name"] = recipe.name;
        receipeDetails["prepTime"] = recipe.prepTime;
        receipeDetails["cookTime"] = recipe.cookTime;
        receipeDetails["recipeIngredient"] = recipe.recipeIngredient;
        receipeDetails["nutrition"] = recipe.nutrition;
        receipeDetails["recipeInstructions"] = recipe.recipeInstructions;
        receipeDetails["description"] = recipe.description;
        receipeDetails["nutritionalTable"] = nutritionalTable;
        receipeDetails["url"] = url;
        receipeDetails["recipeYield"] = recipe["recipeYield"][0];
        receipeDetails["chefName"] = recipe["author"][0].name; 
        
        // setShowUploadedData
      }
    //   console.log("receipe Details",receipeDetails);

    

    formatedReceipe["recipeName"] = receipeDetails["name"];
    formatedReceipe["chefName"] = receipeDetails["chefName"]; 
    formatedReceipe["stage"] = "InProgress";
    formatedReceipe["prepTime"] = extractMinutes(receipeDetails["prepTime"]);
    formatedReceipe["totalCookTime"] = extractMinutes(receipeDetails["cookTime"]);
    formatedReceipe["status"] = "Not Published";
    formatedReceipe["personalNote"] = "Not Published";
    formatedReceipe["id"] = Date.now();
    formatedReceipe["recipeDescription"] = receipeDetails["description"];
    formatedReceipe["url"]= receipeDetails["url"];
    formatedReceipe["servingSize"] =receipeDetails["recipeYield"];
    formatedReceipe["versionNumber"] = 1;

    let personalNote = parsedData.getElementById('mntl-sc-block_20-0');
    if(personalNote) formatedReceipe["personalNote"] = personalNote.innerText;

    // console.log("receipe Details_1",formatedReceipe);

    
    formatedReceipe["ingredients"] = [    
        // {
        //     type: "header",
        //     name: "Ingredients",
        // }
    ];

    receipeDetails["recipeIngredient"].forEach(ingrdient => {
        let formatedIngredient = {
            type: "ingredient",
            name: ingrdient,
            amount: 1,
            unit: ""
        };
        formatedReceipe["ingredients"].push(formatedIngredient); 
    });

    // console.log("receipe format1",formatedReceipe);
    
    formatedReceipe["directions"] = [];
    receipeDetails["recipeInstructions"].forEach(instruction => {
        let formatedInstruction = {
            type: "direction",
            name: instruction.text
        };
        formatedReceipe["directions"].push(formatedInstruction); 
    });

    let nutritions = receipeDetails["nutrition"];

    // formatedReceipe["nutritionalFacts"] = {
    //     calories: nutritions.calories,
    //     carbohydrates: nutritions.carbohydrateContent,
    //     protein: nutritions.proteinContent,
    //     cholesterol: nutritions.cholesterolContent,
    //     sodium: "",
    //     potassium: "",
    //     fiber: nutritions.fiberContent,
    //     sugar: nutritions.sugarContent,
    //     vitaminA: "",
    //     calcium: "",
    //     iron: ""
    //   };

      formatedReceipe["nutritionalFacts"] =  extractNutritionFacts(nutritionalTable);
      formatedReceipe["nutritionalFacts"]["calories"] =  nutritions.calories;

      formatedReceipe["nutritionalFacts"] = convertValues(formatedReceipe["nutritionalFacts"]);

    // console.log("formated Receipe",formatedReceipe);
    
  
    return formatedReceipe;
  }
  
