import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import { NavBar } from "../navbar/NavBar";
import Footer from "../footer/Footer";
import brandlogo from "../../assets/lecuckoologo1.png";
import { RxDashboard } from "react-icons/rx";
import { IoIosAdd } from "react-icons/io";
import { CiImport } from "react-icons/ci";
import { MdDelete, MdEdit, MdForwardToInbox } from "react-icons/md";
import dashBoardData from "../../response";
import pdfRecipeData from "../../PdfRecipeData";
import { SidebarItem } from "./sideBar/SideBar";
import { RecipeTable } from "./recoipeTables/RecipeTable";
import { Link, useNavigate } from "react-router-dom";
import { PiEggCrackLight } from "react-icons/pi";
import  axios from "axios";
import { allReceipeDomainFormater } from "./ReceipeFormator/ReceipeFormator";
import { FoodFormator } from "./ReceipeFormator/FoodFormator";
// import recipeLinks from "../../testReceipes"; // rea
// import recipeLinks from "../../trynew"; // all works
import recipeLinks from "../../testFoodDotComReceipes"; // all works



// /recipeLinks';
export const DashBoard = () => {
  const [activeTab, setActiveTab] = useState("In Progress");
  const [selectSidebarTab, setSelectSidebarTab] = useState("dashboard");
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);

  // const [testingMode, setTestingMode] = useState(true);
  const [testingMode, setTestingMode] = useState(false); 
  const [failedUrl, setFailed] = useState([]);



  

  const API_KEY = "0411cae65c212d17f5b428e4db794ee2";
  let REACT_APP_OPENAI_API_KEY =
  "ea5191e99c364200975b027a5db8cd56" ;

  const [showUploadedData, setShowUploadedData] = useState([]);
  const [importLink, setImportLink] = useState("");

  const handleChange = (event) => {
    setImportLink(event.target.value);
  };

  // Function to append a string to the state array
  const appendString = (newString) => {
    setFailed(prevState => {
      const newState = [...prevState, newString];
      const uniqueStrings = Array.from(new Set(newState));
      console.log("Updated state:", uniqueStrings); // Log updated state
      return newState;
    });
  };

  const fetchFormatedIngredients = async (Ingredients) => {
    try {
      const response = await axios.post(
        "https://lecuckoo-ai.openai.azure.com/openai/deployments/lecuckoov1/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            {
              role: "system",
              content: "You are given a list of objects where each object may represent an ingredient or another type. For objects with 'type' as 'ingredient', the 'name' field contains an amount, a unit, and the ingredient name. Your task is to parse the 'name' field into three separate fields: 'amount', 'unit', and 'name', and update the existing 'amount' and 'unit' fields accordingly. If the 'type' is not 'ingredient', the object should remain unchanged. Return the list of objects with the modified data.\n\nExample transformation:\nBefore:\n[\n  {\"type\": \"header\", \"name\": \"Ingredients\"},\n  {\"type\": \"ingredient\", \"name\": \"1 teaspoon garlic powder\", \"amount\": 1, \"unit\": \"\"}\n]\nAfter:\n[\n  {\"type\": \"header\", \"name\": \"Ingredients\"},\n  {\"type\": \"ingredient\", \"name\": \"garlic powder\", \"amount\": 1, \"unit\": \"teaspoon\"}\n]\n\nPlease process the following list of objects accordingly:",
            },
            {
              role: "user",
              content: `${Ingredients}`,
            },
          ],
          max_tokens: 800,
          temperature: 0.7,
          frequency_penalty: 0,
          presence_penalty: 0,
          top_p: 0.95,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": REACT_APP_OPENAI_API_KEY,
          },
        }
      );
      // console.log(response.data.choices[0].message.content);
      return response.data.choices[0].message.content;    
    } catch (error) {
      console.log("response error",error);  
    } finally {
       
    }
  };

  const fetchData = async (url) => {
    try {
      setShowPopup(true);
      const response = await axios.get(
        `https://api.scraperapi.com/?api_key=${API_KEY}&url=${encodeURIComponent(
          url
        )}`
      );

      let receipeFormated = [];

      if(url.includes("www.allrecipes.com")) receipeFormated = allReceipeDomainFormater(response, url);
      else if(url.includes("www.food.com")) receipeFormated = FoodFormator(response, url);
      else return;
      
      const formatedIngredient = await fetchFormatedIngredients(JSON.stringify(receipeFormated["ingredients"]));
      
      if(formatedIngredient != null) {
          try {
            receipeFormated["ingredients"] = JSON.parse(formatedIngredient);
        } catch (error) {
            // appendString(url);
            console.error("JSON parse error:" + url, error);
            // Handle the error or keep the ingredients property unchanged
        }
      }

      // 

      // console.log("final ",receipeFormated);

      setShowUploadedData((prevData) => {
        const updatedData = [receipeFormated, ...prevData];
        appendString(url);
        sessionStorage.setItem(
          "uploadedRecipeData",
          JSON.stringify(updatedData)
        );
        return updatedData;
      });
    } catch (error) {
      // appendString(url);
      console.error("Error fetching scraped data: \n" + url , error);
    }

    setShowPopup(false);
  };

  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("uploadedRecipeData"));
    if (storedData) {
      setShowUploadedData(storedData);
    }

    if(testingMode){
      recipeLinks.forEach(link => {
        fetchData(link);
      });
    }
  }, []);

  const sidebarOptions = [
    {
      icon: <RxDashboard className="sidebar__icons" />,
      text: "Dashboard",
      tab: "dashboard",
    },
    {
      icon: <IoIosAdd className="sidebar__icons" />,
      text: "New Recipe",
      tab: "newRecipe",
    },
    {
      icon: <CiImport className="sidebar__icons" />,
      text: "Import Recipe",
      tab: "importRecipe",
    },
    {
      icon: <MdForwardToInbox className="sidebar__icons" />,
      text: "Recipe Box",
      tab: "recipeBox",
    },
  ];

  const handleTabClick = (tab) => {
    setSelectSidebarTab(tab);
    if (tab === "newRecipe") {
      navigate("/recipe-editor");
    }
  };

  const deleteUploadedData = (id) => {
    const updatedData = showUploadedData.filter((data) => data.id !== id);
    setShowUploadedData(updatedData);
    sessionStorage.setItem("uploadedRecipeData", JSON.stringify(updatedData));
  };

  const pdfRecipeItems = pdfRecipeData?.flatMap((item) => item) || [];
  sessionStorage.setItem("pdfRecipeData", JSON.stringify(pdfRecipeItems));

  // console.log("pdfRecipeData", pdfRecipeItems);

  const tabs = ["In Progress", "In Review", "Serving"];

  const navigate = useNavigate();

  const handleRedirectToEditor = () => {
    navigate("/recipe-editor");
  };

  const truncateUrl = (url) => {
    if (!url) return "";

    const maxLength = 20;
    if (url.length <= maxLength) return url;

    const halfLength = Math.ceil(maxLength / 2);
    return `${url.substring(0, halfLength)}...${url.substring(
      url.length - halfLength
    )}`;
  };

  return (
    <>
      <NavBar />
      <div className="dashboard">
        <div className="dashboard__sidebar">
          <div className="sidebar__options">
            {sidebarOptions.map((option) => (
              <SidebarItem
                option={option}
                key={option.tab}
                icon={option.icon}
                text={option.text}
                selected={selectSidebarTab === option.tab}
                onClick={() => handleTabClick(option.tab)}
              />
            ))}
          </div>
        </div>
        <div className="dashboard__info">
          <img className="dashboard__logo" src={brandlogo} alt="img" />
          {selectSidebarTab === "dashboard" && (
            <>
              <div className="title">
                <div className="xtra__large">Your Recipes</div>
                <div
                  className="cursor__texts_pink add__newRecipe"
                  onClick={handleRedirectToEditor}
                >
                  <IoIosAdd className="sidebar__icons" /> New Recipe
                </div>
              </div>

              <div className="selection__bars">
                {tabs.map((tab) => (
                  <div
                    key={tab}
                    className={`switch__tabs ${
                      activeTab === tab ? "selected" : ""
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>

              {activeTab && (
                <RecipeTable
                  dashBoardData={dashBoardData}
                  activeTab={activeTab}
                  filterStage={
                    activeTab === "In Progress"
                      ? "InProgress"
                      : activeTab === "In Review"
                      ? "InReview"
                      : "Serving"
                  }
                />
              )}
            </>
          )}
          {(selectSidebarTab === "recipeBox" ||
            selectSidebarTab === "importRecipe") && (
            <>
              {showPopup && (
                <>
                  <div class="popup__overlay" bis_skin_checked="1"></div>
                  <div className="uploading__popup">
                    <div>
                      <PiEggCrackLight className="hatching__icon" />
                      <div className="small">Hatching your new recipe...</div>
                    </div>
                  </div>
                </>
              )}

              <div className="title">
                <div className="xtra__large">Recipe Box</div>
                <div className="small">
                  Paste your favorite receipe site link or upload a file to
                  import!
                </div>
                <div className="import__recipe cursor__texts_pink">
                  <div className="import__input">
                    <input
                      type="text"
                      id="importLink"
                      name="importLink"
                      placeholder="Enter link address"
                      value={importLink}
                      onChange={handleChange}
                    />
                  </div>

                  <div
                    className="import__icons"
                    onClick={() => fetchData(importLink)}
                  >
                    <CiImport className="sidebar__icons" />
                    <strong className="cursor__texts_pink">
                      Import Recipe
                    </strong>
                  </div>
                </div>

                <div className="table">
                  <div className="table__row table__header">
                    <div className="table__cell recipe__name">Recipe Name</div>
                    <div className="table__cell description_name">
                      Description
                    </div>
                    <div className="table__cell status">Original URL</div>
                    <div className="table__cell recipe__name">Chef Name</div>
                    <div className="table__cell action">Edit</div>
                    <div className="table__cell action">Delete</div>
                  </div>
                  {error && <div className="file__match__error">{error}</div>}
                  {showUploadedData.map((data, index) => (
                    <div className="table__row">
                      <div className="table__cell">{data?.recipeName}</div>
                      <div className="table__cell">
                        {data?.recipeDescription}
                      </div>
                      <div className="table__cell">
                        <a
                          href={data?.url}
                          className="pdf___url cursor__texts_pink"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {truncateUrl(data?.url)}
                        </a>
                      </div>
                      <div className="table__cell">
                        {data.chefName}
                        </div>
                      <div className="table__cell">
                        <Link
                          style={{ color: "black" }}
                          to={`/recipe-editor?importId=${data.id}`}
                        >
                          <MdEdit className="icons" />
                        </Link>
                      </div>
                      <div className="table__cell">
                        <MdDelete
                          className="icons"
                          onClick={() => deleteUploadedData(data.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {showUploadedData.length === 0 && (
                  <div className="brows__recipe__section">
                    <div className="small brows__text">
                      You haven’t saved any recipes yet. {" "}
                      <strong className="cursor__texts_pink">
                        Import one!
                      </strong>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {selectSidebarTab === "newRecipe" && (
            <>
              <div className="xtra__large">This is newRecipe</div>
              <div className="medium still__working">Stll Cooking</div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
