import "./HomePage.css";
import React, {useState } from "react";
import { NavBar } from "../navbar/NavBar";
import Homevideo from "../../assets/Compressedhome.mp4";
import brandlogo from "../../assets/lecuckoologo1.png";
import { Recipes } from "./recipes/Recipes";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import { Loader } from "../../Loader";

export const HomePage = () => {


  const [isVideoLoading , setIsVideoLoading] = useState(true)


  return (
    <>
      <NavBar />

      {isVideoLoading  && <Loader/>}
        <main>
          <section className="hero__section">
            <div className="hero__image__container">
              <div className="textlogo__container">
                <div className="sub__navbar__section">
                  <div className="brand__logo">LeCuckoo <span style={{fontSize: "20px"}}> <sup>BETA</sup></span> </div>
                  <div className="logo__title">
                    Cultivating Community & Food
                  </div>
                </div>
                <img className="dashboard__logo" src={brandlogo} alt="img" />
              </div>
              <video
                className="bg__cover"
                autoPlay
                muted
                loop
                playsInline
                preload="metadata"
                onCanPlayThrough={()=>setIsVideoLoading(false)}
              >
                <source src={Homevideo} type="video/mp4"></source>
              </video>
            </div>
            <div className="gets__started__button__container">
              <Link to="/still-cooking" className="medium started">
                Lets Get Cooking
              </Link>
              <Link to="/recipe-editor" className="medium started">
                Create Recipe
              </Link>
              <Link to="/still-cooking" className="medium started">
                Cooking Class
              </Link>
              <Link to="/still-cooking" className="medium started">
                Find a chef
              </Link>
              <Link to="/still-cooking" className="medium started">
                Join Our Community
              </Link>
            </div>
          </section>
          <section className="hero__products__section">
            <div className="carosal__title">Show us what you're cooking</div>
            <Recipes />
          </section>
        </main>
      <Footer />
    </>
  );
};
