import React, { useState, useEffect } from "react";
import { Navlinks } from "./navLinks/Navlinks";
import { Link, useLocation } from "react-router-dom";
import "./PageNavigations.css";

export const PageNavigations = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    if (email && password) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const location = useLocation();

  const login = location.pathname === "/login";
  const register = location.pathname === "/register";

  const links = [
    {
      title: "Dashboard",
      path: "/dashboard",
    },
  ];

  return (
    <div className="page__navigations">
      {(login || register) || isLoggedIn ? null : 
          <>
          <Link className="signin__signup__btn" to="/login">
            Sign in
          </Link>
        </>
      }

      {isLoggedIn ? (
        <div className="login__and__signup__tabs">
          <div className="navigations__container">
            {links.map((link) => (
              <Navlinks item={link} key={link.title}
              activeClass={location.pathname === link.path ? 'active' : ''}
              />
            ))}
          </div>
          <div className="logout__btn" onClick={handleLogout}>
            Logout
          </div>
        </div>
      ) : (
        <>
          {register && (
            <>
              <div className="allready__signin">
                <div className="allready__signin_text">Already a user?</div>
                <Link className="signin__signup__btn" to="/login">
                  Sign in
                </Link>
              </div>
            </>
          )}

          {login && (
            <>
              <div className="allready__signin">
                <div className="allready__signin_text">
                  Don’t have an account?
                </div>
                <Link className="signin__signup__btn" to="/register">
                  Sign Up
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const handleLogout = () => {
  ["email", "password"].forEach((item) => localStorage.removeItem(item));
  sessionStorage.removeItem("recipes");

  window.location.href = "/";
};
