import React from "react";
import "./ViewRecipe.css";
import brandlogo from "../../../../assets/lecuckoologo1.png";
import profile from "../../../../assets/profile.jpeg";
import { Nutritionals } from "../../../recipieEditor/nutritional/Nutritionals.js";
import { Link, useParams } from "react-router-dom";
import { NavBar } from "../../../navbar/NavBar.js";
import Footer from "../../../footer/Footer.js";
import { IoReturnUpBack } from "react-icons/io5";
import { ReactComponent as TiktokIcon } from "../../../../assets/tiktok.svg";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";

export const ViewRecipe = () => {
  const { id } = useParams();

  const recipeData = JSON.parse(sessionStorage.getItem("recipes")) || [];

  const viewData = recipeData.find((recipe) => recipe.id === parseInt(id));

  console.log("recipeToEdit", viewData);

  if (!viewData) {
    return <div>Recipe not found</div>;
  }

  const {
    recipeName,
    versionNumber,
    prepTime,
    recipeDescription,
    servingSize,
    totalCookTime,
    ingredients,
    directions,
    nutritionalFacts,
  } = viewData;

  console.log(nutritionalFacts);

  const lines = [
    "Your recipe is good so far, but here are some suggested improvements:",
    "1. Check your spelling in the directions section.",
    "2. Be more clear with your directions, for example '...'",
    "3. Ensure your ingredients order is correct.",
    "Is that clear, or do you want some examples?",
  ];

  return (
    <>
      <NavBar />
      <div className="view__recipe__section">
        <div className="redirect__back___btns">
          <Link to="/dashboard" className="medium go__back">
            <IoReturnUpBack /> Back
          </Link>
          {/* <div className="medium testing__done ">
            <MdOutlineDone /> Done
          </div> */}
        </div>

        <img className="dashboard__logo" src={brandlogo} alt="img" />
        <div className="view__left">
          <img className="view__recipe_logo" src={profile} alt="img" />
          <div className="medium lineSpace">Symone Opara</div>
          <div className="small">
            Renowned chef with 20+ years of experience. Specializes in
            innovative fusion cuisine. Awarded 3 Michelin stars. Passionate
            about sustainable ingredients.
          </div>

          <Link to="/still-cooking" className="cursor__texts_pink verHeight">
            {" "}
            Chef’s Page
          </Link>
          <br />
          <Link to="/still-cooking" className="cursor__texts_pink verHeight">
            See more recipes
          </Link>
          <br />
          <Link to="/still-cooking" className="cursor__texts_pink verHeight">
            Chef’s Cookbook
          </Link>
          <div>
            <div className="medium">Services Provided</div>
            <div className="medium_semibold">
              <div className="provider">Private Parties</div>
              <div className="provider">Catering</div>
            </div>
            <div className="medium">Follow me on:</div>
            <div className="social__media__section">
              <div className="icons">
                <FaInstagram className="react__icons" />
                <TiktokIcon className="react__icons" />
                {/* <AiOutlineTiktok className="react__icons" /> */}
                <AiOutlineFacebook className="react__icons" />
                <FaGoogle className="react__icons" />
                {/* <CiLinkedin className="react__icons" /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="view__center scrollbar">
          <div className="xtra__large lineSpace lineSpace">{recipeName}</div>
          <div className="small lineSpace">Version: {versionNumber}</div>
          <div className="medium_semibold lineSpace">{recipeDescription}</div>

          <div className="small lineSpace">Serving Size: {servingSize}</div>
          <div className="small lineSpace">Prep Time: {prepTime} min</div>
          <div className="small lineSpace">Cook Time: {totalCookTime} min</div>

          <div className="xtra__large">Ingredients</div>
          {ingredients.map((ingredient, index) => (
            <div key={index} className="information__ingredients">
              {ingredient.type === "header" ? (
                <div className="medium_semibold">{ingredient.name}</div>
              ) : (
                <div className="medium_semibold ingredients__item">
                  {ingredient.amount} {ingredient.unit} {ingredient.name}
                </div>
              )}
            </div>
          ))}

          <div className="xtra__large">Directions</div>
          {directions.map((direction, index) => (
            <div key={index} className="information__ingredients">
              {direction.type === "header" && (
                <div className="medium">{direction.name}</div>
              )}

              {direction.type === "direction" && (
                <div className="medium_semibold">{direction.name}</div>
              )}
            </div>
          ))}

          <div className="feedback_link">
            <Link className="cursor__texts_pink" to="/still-cooking">
              Give Feedback
            </Link>
          </div>
        </div>
        <div className="view__right">
          <Nutritionals nutritionalValues={nutritionalFacts} />
          <div className="gpt__section">
            <div className="review__message scrollbar small">
              <div className="review__content">
                {lines.map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            </div>

            <div className="review__input__box">
              <input className="small" placeholder="Enter  message here" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
