function extractMinutes(duration) {
    // Regular expression to extract the components
    const regex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?/;
    const matches = duration.match(regex);

    // Extract the components, defaulting to 0 if not present
    const years = parseFloat(matches[1]) || 0;
    const months = parseFloat(matches[2]) || 0;
    const days = parseFloat(matches[3]) || 0;
    const hours = parseFloat(matches[4]) || 0;
    const minutes = parseFloat(matches[5]) || 0;
    const seconds = parseFloat(matches[6]) || 0;

    // Convert all components to minutes
    const totalMinutes = (years * 525600) + (months * 43800) + (days * 1440) + (hours * 60) + minutes + (seconds / 60);
    return totalMinutes;
}

export function convertValues(obj) {
    let result = {};
    
    for (let key in obj) {
        let value = obj[key];

        if (value === '') {
            result[key] = 0;
        } else if (typeof value === 'string') {
            if (value.endsWith('mg')) {
                result[key] = parseInt(value.slice(0, -2)) / 1000;
            }
            else if (value.endsWith('g')) {
                result[key] = parseInt(value.slice(0, -1));
            }  else {
                result[key] = parseInt(value);
            }
        } else {
            result[key] = parseInt(value);
        }
    }
    
    return result;
}


export  function FoodNetworkFormator(response, url){

    let formatedReceipe = {};
    console.log("FoodNetworkFomator", response.data);
    const parsedData = new DOMParser().parseFromString(response.data, 'text/html');
    console.log("parsedData",JSON.stringify(parsedData));
    
    const scriptTag = parsedData.querySelector('script[type="application/ld+json"]');
    console.log("Script Tag",scriptTag);
        
    // Parse the JSON content
    const receipeDetails = JSON.parse(scriptTag.textContent)[0];
    console.log("source",receipeDetails);
    console.log("trimed", receipeDetails)

    formatedReceipe["recipeName"] = receipeDetails["headline"];
    formatedReceipe["stage"] = "InProgress";
    formatedReceipe["prepTime"] = extractMinutes(receipeDetails["cookTime"]);
    formatedReceipe["totalCookTime"] = extractMinutes(receipeDetails["totalTime"]);
    formatedReceipe["status"] = "Not Published";
    formatedReceipe["personalNote"] = "Not Published";
    formatedReceipe["id"] = Date.now();
    formatedReceipe["recipeDescription"] = receipeDetails["description"];
    formatedReceipe["url"]=url;              //  receipeDetails["url"];

    // formatedReceipe["servingSize"] =receipeDetails["recipeYield"];
    formatedReceipe["versionNumber"] = 1;

    console.log("ingredients",receipeDetails["recipeIngredient"]);

    formatedReceipe["ingredients"] = [    
        {
            type: "header",
            name: "Ingredients",
        }
    ];

    receipeDetails["recipeIngredient"].forEach(ingrdient => {
        let formatedIngredient = {
            type: "ingredient",
            name: ingrdient,
            amount: 1,
            unit: ""
        };
        formatedReceipe["ingredients"].push(formatedIngredient); 
    });

    formatedReceipe["directions"] = [];
    receipeDetails["recipeInstructions"].forEach(instruction => {
        let formatedInstruction = {
            type: "direction",
            name: instruction.text
        };
        formatedReceipe["directions"].push(formatedInstruction); 
    });

    let nutrition = receipeDetails["nutrition"]

    formatedReceipe["nutritionalFacts"] = {
        calories: nutrition["calories"],
        carbohydrates: nutrition["carbohydrateContent"],
        protein: nutrition["proteinContent"],
        cholesterol: nutrition["cholesterolContent"],
        sodium: nutrition["sodiumContent"],
        potassium: "",
        fiber: nutrition["fiberContent"],
        sugar: nutrition["sugarContent"],
        vitaminA: "",
        calcium: "",
        iron: ""
    }

    formatedReceipe["nutritionalFacts"] = convertValues(formatedReceipe["nutritionalFacts"]);



    // {
    //     "@type": "NutritionInformation",
    //     "servingSize": "1 of 10 servings",
    //     "calories": "411",
    //     "fatContent": "29g",
    //     "saturatedFatContent": "10g",
    //     "carbohydrateContent": "24g",
    //     "fiberContent": "8g",
    //     "sugarContent": "11g",
    //     "proteinContent": "20g",
    //     "cholesterolContent": "47mg",
    //     "sodiumContent": "711mg"
    // }

    // nutritionalFacts: {
    //     calories: 210,
    //     carbohydrates: 45,
    //     protein: 170,
    //     cholesterol: 123,
    //     sodium: 90,
    //     potassium: 52,
    //     fiber: 45,
    //     sugar: 30,
    //     vitaminA: 48,
    //     calcium: 60,
    //     iron: 34,
    //   },

    console.log("fomated Receipe",formatedReceipe);

    return formatedReceipe;
}
