import React, { useState } from "react";
import "./RecipeTesting.css";
import { MdDelete } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import dashBoardData from "../../response";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const RecipeTesting = ({
  openTestForm,
  testingFormData,
  submitForTesting,
}) => {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formErrors, setFormErrors] = useState({ formError: "" });

  const navigate = useNavigate();

  const submitRecord = (e) => {
    e.preventDefault();
    if (
      !selectedEmails.length ||
      !document.getElementById("startDate").value ||
      !document.getElementById("endDate").value
    ) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        formError: "One or more fields are missing.",
      }));
      setTimeout(() => {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          formError: "",
        }));
      }, 3000);
      return;
    }

    const updatedFormData = {
      ...testingFormData,
      recipeTesting: {
        emails: selectedEmails,
        startDate: document.getElementById("startDate").value,
        endDate: document.getElementById("endDate").value,
      },
    };
    
    const existingRecipes = JSON.parse(sessionStorage.getItem("recipes"));

    existingRecipes.push(updatedFormData);

    sessionStorage.setItem("recipes", JSON.stringify(existingRecipes));

    console.log("Updated sessionStorage:", existingRecipes);
    setSelectedEmails([]);
    setEmailInput("");
    setEmailError("");
    document.getElementById("startDate").value = "";
    document.getElementById("endDate").value = "";

    openTestForm(false);
    // resetForm()

    submitForTesting()
    toast.success("Submitted for testing", {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      bodyClassName: "single__line__toast",
    });
    navigate("/dashboard");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && emailInput.trim() !== "") {
      e.preventDefault();
      if (!selectedEmails.includes(emailInput)) {
        setSelectedEmails([...selectedEmails, emailInput]);
        setEmailInput("");
      } else {
        setEmailError("Email already added.");
      }
    }
  };

  const deleteElement = (index) => {
    const updatedEmails = [...selectedEmails];
    updatedEmails.splice(index, 1);
    setSelectedEmails(updatedEmails);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const changeTextInput = (e) => {
    const value = e.target.value;
    setEmailInput(value);
    const isValid = validateEmail(value);
    if (!isValid) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const getMaxDate = () => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 21);
    return maxDate.toISOString().split("T")[0];
  };

  if (sessionStorage.getItem("recipes") == null) {
    let localRecipeItems = [];

    dashBoardData?.forEach((item, index) => {
      localRecipeItems = localRecipeItems.concat(item);
    });
    sessionStorage.setItem("recipes", JSON.stringify(localRecipeItems));
  }

  return (
    <>
      <div className="recipe__testing__container">
        {/* <div className="top__btn__action">
          <div className="medium go__back" onClick={() => openTestForm(false)}>
            <IoReturnUpBack /> Back
          </div>
          <div className="medium testing__done ">
            <MdOutlineDone /> Done
          </div>
        </div> */}
        {/* <div>
          <IoCloseOutline />
        </div> */}
        <div className="close__btn" onClick={() => openTestForm(false)}>
          <IoCloseOutline className="icons" />
        </div>
        <div className="testing__title">
          <div className="xtra__large">Submit for Testing</div>
          <div className="small">
            Your recipe is ready for testing. Please select your community
            below.
          </div>
        </div>
        <form className="private__testing" onSubmit={submitRecord}>
          <div className="medium">Private Testers</div>
          <div className="small text ">
            Confirm your testing community or add new private testers below.
          </div>
          <div className="small">Community 1's testers</div>

          {selectedEmails?.length > 0 && (
            <div className="testers__emails scrollbar">
              {selectedEmails.map((email, index) => (
                <div key={index} className="selected__emails">
                  <div>{email}</div>
                  <MdDelete
                    className="icons blur"
                    onClick={() => deleteElement(index)}
                  />
                </div>
              ))}
            </div>
          )}

          <div className="email__input">
            <input
              type="text"
              placeholder="Enter name or email address"
              value={emailInput}
              onChange={changeTextInput}
              onKeyPress={handleKeyPress}
              className={emailError ? "input__error" : ""}
            />
            <MdDelete
              className="icons blur"
              onClick={() => setEmailInput("")}
            />
          </div>
          {emailError && <div className="error__message">{emailError}</div>}

          <div className="date__section">
            <div className="small text ">
              Press enter to add testers
              {/* Adjust your testing window for your private testers below */}
            </div>
            <div className="timetext">Recipe Testing Timeframe</div>
            <div className="date__picker__section">
              <input
                type="date"
                id="startDate"
                name="testing"
                placeholder="2024-04-22"
                max={getMaxDate()}
              />
              -
              <input
                type="date"
                id="endDate"
                name="testing"
                placeholder="2024-13-5"
                max={getMaxDate()}
              />
            </div>
          </div>
          {formErrors.formError && (
            <div className="error__msg">{formErrors.formError}</div>
          )}
          <div className="testsubmit__btn">
            <button className="submit__testing__form" type="submit">
              Submit
            </button>
          </div>
        </form>
        <div className="public__testing">
          <div className="coop__testers medium">
            Public Testers (Coop Testers)
          </div>
          <div className="submit__testing__form">
            Submit to coop testers <br />
            (Testing complete in three weeks)
          </div>
        </div>
      </div>
    </>
  );
};
