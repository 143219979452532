import React, { useState, useEffect } from "react";
import "./StillCooking.css";
import brandlogo from "../../assets/lecuckoologo1.png";
import { NavBar } from "../navbar/NavBar";
import Footer from "../footer/Footer";
import backingVideo from "../../assets/compressedbacking.mp4";
import { Loader } from "../../Loader";
import EmbedVismeForm from './EmbedVismeForm';

export const StillCooking = () => {
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  // const [showWidget, setShowWidget] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem('vismeforms_80550_closed');

  }, []);

  return (
    <>
      <NavBar />
      {isVideoLoading && <Loader />}
      <div className="still-baking">
        <video
          className="cooking__bg"
          autoPlay
          muted
          loop
          playsInline
          onCanPlayThrough={() => setIsVideoLoading(false)}
        >
          <source src={backingVideo} type="video/mp4"></source>
        </video>
      </div>
      <EmbedVismeForm />
      <Footer />
    </>
  );
};
