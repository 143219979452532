import React from "react";

export const SidebarItem = ({option, icon, text, selected, onClick }) => {
  return (
    <div className={`actions ${selected ? "selected" : ""}`} onClick={onClick}>
      {icon}
      <div>{text}</div>
    </div>
  );
};

SidebarItem.defaultProps = {
  selected: false,
};
