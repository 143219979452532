import React from 'react'
import "./Navlinks.css"
import { Link } from 'react-router-dom'

export const Navlinks = ({ item, activeClass }) => {
  return (
    <Link to={item.path} className={`navlinks ${activeClass}`}>
      {item.title}
    </Link>
  );
};

