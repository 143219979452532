import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import brandlogo from "../../assets/lecuckoologo1.png";
import "./UserLogin.css";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { ReactComponent as TiktokIcon } from "../../assets/tiktok.svg";
import { CiLinkedin } from "react-icons/ci";
import { toast } from "react-toastify";
import { NavBar } from "../../Components/navbar/NavBar";
import Footer from "../../Components/footer/Footer";
import loginvideo from "../../assets/compressedlogin.mp4";
import { Loader } from "../../Loader";

export const UserLogin = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [isVideoLoading, setIsVideoLoading] = useState(true);

  


  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email.includes("@") || !email.includes(".com")) {
      setEmailError("Invalid email format.");
      return;
    } else {
      setEmailError("");
    }

    if (email !== "slopara@lecuckoo.com" || password !== "123") {
      setPasswordError("Invalid Credentials");
      return;
    } else {
      setEmailError("");
      setPasswordError("");
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
      setIsLoggedIn(true);
      navigate("/dashboard");
    }

    toast.success("Welcome back", {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      bodyClassName: "single__line__toast",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (!value.includes("@") || !value.includes(".com")) {
        setEmailError("Invalid email format.");
      } else {
        setEmailError("");
      }
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <>
      <NavBar />
      {isVideoLoading && <Loader />}

      <div className="login__section">
        <video
          className="loginbg__cover"
          autoPlay
          muted
          loop
          playsInline
          onCanPlayThrough={() => setIsVideoLoading(false)}
        >
          <source src={loginvideo} type="video/mp4"></source>
        </video>
        <div className="login__container">
          <img className="brand" src={brandlogo} alt="img" />
          <div className="medium">Sign in</div>

          <div className="social__media__section__signin">
            <div className="small">You may also log in with</div>
            <div className="icons">
              <FaInstagram className="react__icons" />
              <TiktokIcon className="react__icons" />
              {/* <AiOutlineTiktok className="react__icons" /> */}
              <AiOutlineFacebook className="react__icons" />
              <FaGoogle className="react__icons" />
              {/* <CiLinkedin className="react__icons" /> */}
            </div>
          </div>
          <form className="login__form" onSubmit={handleSubmit}>
            <div className="login__form__group">
              <input
                type="email"
                id="email"
                name="email"
                // placeholder="Email address"
                value={email}
                onChange={handleChange}
                required
              />
              <div className="signup__label" htmlFor="firstname">
                Email Address
              </div>
              {emailError && <div className="error__msg">{emailError}</div>}
            </div>
            <div className="login__form__group">
              <input
                type="password"
                id="password"
                name="password"
                // placeholder="Password"
                value={password}
                onChange={handleChange}
                required
              />
              <div className="signup__label" htmlFor="firstname">
                Password
              </div>

              {passwordError && (
                <div className="error__msg">{passwordError}</div>
              )}
            </div>

            <div className="remember__group">
              <div className="remember__password_text">
                <input
                  type="checkbox"
                  id="agreeTerms"
                  name="agreeTerms"
                  // required
                />
                <label htmlFor="agreeTerms">Remember me</label>
              </div>
              <Link to="/still-cooking" className="navLink__redirect">
                Forgot password
              </Link>
            </div>

            <button type="submit" className="login__btn">
              Sign in
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
