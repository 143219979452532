import React, { useState, useEffect } from "react";
import "./Ingredients.css";
import { MdDelete } from "react-icons/md";
import { IoIosAdd, IoMdMenu } from "react-icons/io";
import loadIngredients from "../../../Realdatas.js";

export const Ingredients = ({
  // personalNote,
  // setPersonalNote,
  checkIngredientExistence,
  setIngredients,
  ingredients,
  ingredientInfo,
  setIngredientInfo,
  // updateNutritionalValues,
  handleDragStart,
  handleDrop,
  clearItemFields,
  deleteRecipes,
}) => {
  const amount = ingredientInfo.amount;
  const unit = ingredientInfo.unit;
  const ingredientName = ingredientInfo.name;

  const [focusedInputIndex, setFocusedInputIndex] = useState(null);
  const [focusedBorderIndex, setFocusedBorderIndex] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('click',addOnOutsideClick );
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('click',addOnOutsideClick );
  
    };
  }, []);

  const handleSuggestionClick = (index, suggestion) => {
    const event = {
      target: {
        value: suggestion,
      },
    };

    // console.log("event called", event);
    handleInputChange(event, index, "name");
    setSuggestions([]); // Hide suggestions after selecting
  };

  const handleFocus = (suggestionIndex, borderIndex) => {
    // console.log("new focus called", index, focusedInputIndex);
    setFocusedInputIndex(suggestionIndex);
    setFocusedBorderIndex(borderIndex);
    setSuggestions(Object.keys(loadIngredients)); // Reset suggestions to all keys
  };

  const handleBlur = (index) => {
    // console.log(index +","+ focusedInputIndex);
    // if(index == focusedInputIndex)setFocusedInputIndex(null);

    // if(index == focusedInputIndex) setTimeout(() => setFocusedInputIndex(null), 100); 
    // Delay to allow suggestion click
    // setTimeout(() => setFocusedInputIndex(null), 100); 
    // setFocusedInputIndex(null);
  };

  const handleClickOutside = (event) => {
    const type1Elements = document.querySelectorAll('.table__contents_header');
    const type2Elements = document.querySelectorAll('.table__contents');

    const type2 = document.querySelector('.customBox');

    // console.log("click called");
    
    let clickedInsideType1 = false;
    
    type1Elements.forEach((type1) => {
      if (type1.contains(event.target)) {
        // console.log("table contents heder ");
        clickedInsideType1 = true;
      }
    });

    type2Elements.forEach((type2) => {
      if (type2.contains(event.target)) {
        // console.log("table contents heder ");
        clickedInsideType1 = true;
      }
    });



    if (!clickedInsideType1 && (type2 == null || !type2.contains(event.target))) {
      // setFocusState(false);
      // console.log("called outside");
      setFocusedInputIndex(null);
      setFocusedBorderIndex(null);

    }
  };

  const addOnOutsideClick = (event) => {
    // console.log("add outside clicked");
    // console.log("info:",ingredientInfo);
    // addIngridents("ingredient", false);
  }



  const addIngridents = (type, isToShowError) => {
    // console.log(ingredientName, amount,unit);
    // console.log("ingredients",ingredientInfo);
    if (
      (type === "ingredient" &&
        ingredientName.trim() !== "" &&
        amount.trim() !== "" &&
        unit.trim() !== "") ||
      (type === "header" && ingredientName.trim() !== "")
    ) {
      const newItem =
        type === "ingredient"
          ? { type, name: ingredientName, amount, unit }
          : { type, name: ingredientName };

      const updatedIngredients = [...ingredients, newItem];
      setIngredients(updatedIngredients);
      checkIngredientExistence(updatedIngredients);

      clearItemFields();
    } else {
      if(isToShowError) alert("Invalid inputs");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addIngridents("ingredient", false);
    }
  };

  const handleInputChange = (e, index, field) => {
    const updatedIngredients = ingredients.map((item, i) =>
      i === index ? { ...item, [field]: e.target.value } : item
    );
    checkIngredientExistence(updatedIngredients);
    setIngredients(updatedIngredients);
  };

  const handleInputChangeWithSuggestions = (e, index, field) => {
    const value = e.target.value;

    // Update suggestions if the field is 'name'
    if (field === "name") {
      if (value) {
        const filteredSuggestions = Object.keys(loadIngredients).filter(
          (ingredient) => ingredient.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions(Object.keys(loadIngredients));
      }
    }

    handleInputChange(e, index, field);
  };

  return (
    <>
      <div className="adding__items__section">
        <div className="title">Ingredients</div>
        <div className="table__head">
          <div>
            <IoMdMenu className="icons" />
          </div>
          <div>Ingredient Name</div>
          <div>Amount</div>
          <div>Unit</div>
          <div>Delete</div>
        </div>

        {ingredients.map((item, index) => (
          <div key={index}>
            {item.type === "header" && (
              <div
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) =>handleDrop(e, index, ingredients, setIngredients)}
                className={`table__contents_header ${
                  focusedBorderIndex === index ? "focus_input" : ""
                }`}
                onClick={() => handleFocus(index, index)}
              >
                <div className="action__icons">
                  <IoMdMenu className="icons" />
                </div>

                <div className="header__text">
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) => handleInputChange(e, index, "name")}
                    onFocus={() => handleFocus(index, index)}
                    onBlur={() => handleBlur(index)}
                    className="input__editable highlighter"
                  />
                </div>

                <div
                  onClick={() =>
                    deleteRecipes(ingredients, setIngredients, index)
                  }
                  className="action__icons"
                >
                  <MdDelete className="icons" />
                </div>
              </div>
            )}
            {item.type === "ingredient" && (
              <div
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) =>handleDrop(e, index, ingredients, setIngredients)}
                className={`table__contents newItems ${
                  focusedBorderIndex === index ? "focus_input" : ""
                }`}
                style={{ position: "relative" }}
                onClick={() => {
                  // console.log("clicked on the div");
                  if(index != focusedInputIndex) {
                    // console.log(index + "," +focusedInputIndex );
                    // console.log("div clicked and updated");
                    handleFocus(null, index);
                  }
                }}
              >
                <div className="action__icons">
                  <IoMdMenu className="icons" />
                </div>
                <div className="scrollbar">
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      handleInputChangeWithSuggestions(e, index, "name")
                    }
                    onFocus={() => handleFocus(index, index)}
                    onBlur={() => handleBlur(index)}
                    className="scrollbar input__editable"
                  />
                  {focusedInputIndex === index && suggestions.length > 0 && (
                    <div className="customBox">
                      {suggestions.map((suggestion, sIndex) => (
                        <div
                          key={sIndex}
                          onClick={() =>
                            handleSuggestionClick(index, suggestion)
                          }
                          style={{ cursor: "pointer", padding: "10px" }}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* {item.name} */}
                </div>
                <div>
                  {/* <input
                      type="text"
                      value={item.amount}
                      onChange={(e) => handleInputChange(e, index, 'amount')}
                      className="input__editable"
                      /> */}
                  <input
                    type="number"
                    placeholder="0"
                    className="input-box"
                    value={item.amount}
                    onChange={(e) => handleInputChange(e, index, "amount")}
                    onFocus={() => handleFocus(null, index)}
                  />
                  {/* {item.amount} */}
                </div>
                <div>
                  <input
                    type="text"
                    value={item.unit}
                    onChange={(e) => handleInputChange(e, index, "unit")}
                    className="input__editable"
                    onFocus={() => handleFocus(null, index)}
                  />
                  {/* {item.unit} */}
                </div>
                <div
                  onClick={() =>
                    deleteRecipes(ingredients, setIngredients, index)
                  }
                  className="action__icons"
                >
                  <MdDelete  className="icons" />
                </div>
              </div>
            )}
          </div>
        ))}

        <div
          // className="table__contents newItems"
          // onClick={() => handleFocus(-1, -1)}
          onClick={() => {
            // console.log("clicked on the div");
            if( -1 != focusedInputIndex) {
              // console.log(index + "," +focusedInputIndex );
              // console.log("div clicked and updated");
              handleFocus(null, -1);
            }
          }}
          className={`table__contents newItems 
              ${focusedBorderIndex === -1 ? "focus_input" : ""}
          `}
          style={{ position: "relative" }}
        >
          <div className="draggable__icon">
            <IoMdMenu className="icons" />
          </div>
          <div className="ingredient__input">
            <input
              type="text"
              placeholder="Enter ingredients"
              className="input-box"
              value={ingredientName}
              onChange={(e) => {
                let value = e.target.value;
                if (value) {
                  const filteredSuggestions = Object.keys(
                    loadIngredients
                  ).filter((ingredient) =>
                    ingredient.toLowerCase().includes(value.toLowerCase())
                  );
                  setSuggestions(filteredSuggestions);
                } else {
                  setSuggestions(Object.keys(loadIngredients));
                }
                setIngredientInfo({ ...ingredientInfo, name: value });
              }}
              onFocus={() => handleFocus(-1, -1)}
              onBlur={() => {
                handleBlur(-1);
                addIngridents("ingredient", false);
              }
            }
              onKeyPress={handleKeyPress}
              
            />
            {focusedInputIndex === -1 && suggestions.length > 0 && (
              <div className="customBox">
                {suggestions.map((suggestion, sIndex) => (
                  <div
                    key={sIndex}
                    onClick={() => {
                      handleFocus(null,-1);
                      setIngredientInfo({
                        ...ingredientInfo,
                        name: suggestion,
                      });
                    }}
                    style={{ cursor: "pointer", padding: "10px" }}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="amount__input">
            <input
              type="number"
              placeholder="0"
              className="input-box"
              value={amount}
              onChange={(e) =>
                setIngredientInfo({ ...ingredientInfo, amount: e.target.value })
              }
              onFocus={() => handleFocus(null, -1)}
              onKeyPress={handleKeyPress}
              onBlur={() => {addIngridents("ingredient", false);}}
            />
          </div>
          <div className="unit__input">
            <div className="ingredient__input">
              <input
                type="text"
                placeholder="g"
                className="input-box"
                value={unit}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const textOnlyValue = newValue.replace(/[0-9]/g, "");
                  setIngredientInfo({ ...ingredientInfo, unit: textOnlyValue });
                }}
                onFocus={() => handleFocus(null, -1)}
                onKeyPress={handleKeyPress}
                onBlur={() => {addIngridents("ingredient", false);}}
                    
              />
            </div>
          </div>
          <MdDelete onClick={clearItemFields} className="icons" />
        </div>
        <div className="btn__sections">
          <div
            className="cursor__texts_blue bold"
            onClick={() => addIngridents("ingredient", true)}
          >
            <IoIosAdd /> Add Ingredient
          </div>
          <div
            className="cursor__texts_blue bold"
            onClick={() => addIngridents("header", true)}
          >
            <IoIosAdd /> Add Header
          </div>
        </div>
      </div>
    </>
  );
};
