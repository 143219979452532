   let urls=  `https://www.food.com/recipe/easy-garlic-chicken-5478
https://www.food.com/recipe/pulled-pork-crock-pot-131018
https://www.food.com/recipe/to-die-for-crock-pot-roast-27208
https://www.food.com/recipe/melt-in-your-mouth-chicken-breasts-37336
https://www.food.com/recipe/better-than-olive-garden-alfredo-sauce-141983
https://www.food.com/recipe/fannie-farmers-classic-baked-macaroni-cheese-135350
https://www.food.com/recipe/authentic-italian-meatballs-92095
https://www.food.com/recipe/perfect-pork-tenderloin-63828
https://www.food.com/recipe/beths-melt-in-your-mouth-barbecue-ribs-oven-107786
https://www.food.com/recipe/soft-snickerdoodle-cookies-97496
https://www.food.com/recipe/crock-pot-whole-chicken-33671
https://www.food.com/recipe/crock-pot-macaroni-cheese-257276
https://www.food.com/recipe/simply-sour-cream-chicken-enchiladas-129926
https://www.food.com/recipe/scalloped-potatoes-85629
https://www.food.com/recipe/joleans-cheese-potato-smoked-sausage-casserole-126623
https://www.food.com/recipe/chimichurri-21151
https://www.food.com/recipe/basil-pesto-8650
https://www.food.com/recipe/german-potato-salad-27415
https://www.food.com/recipe/potato-salad-with-capers-kalamata-olives-and-artichoke-hearts-221292
https://www.food.com/recipe/spinach-artichoke-stuffed-pecan-crusted-chicken-breasts-282945
https://www.food.com/recipe/fresh-blueberry-cobbler-71109
https://www.food.com/recipe/marinated-steak-lettuce-wraps-533801
https://www.food.com/recipe/zucchini-bread-17123
https://www.food.com/recipe/homemade-lemonade-71957
https://www.food.com/recipe/carolina-low-country-shrimp-boil-311478
https://www.food.com/recipe/beths-melt-in-your-mouth-barbecue-ribs-oven-107786
https://www.food.com/recipe/peach-and-roasted-red-pepper-bruschetta-213758
https://www.food.com/recipe/courgette-zucchini-pasta-with-chili-garlic-parmesan-249180
https://www.food.com/recipe/miso-glazed-eggplant-535980
https://www.food.com/recipe/marinated-steak-lettuce-wraps-533801
https://www.food.com/recipe/bourbon-chicken-45809
https://www.food.com/recipe/best-banana-bread-2886
https://www.food.com/recipe/barbs-gumbo-82288
https://www.food.com/recipe/to-die-for-crock-pot-roast-27208



				
https://www.food.com/recipe/green-beans-on-the-grill-336841				
https://www.food.com/recipe/green-beans-on-the-grill-336842				
https://www.food.com/recipe/watermelon-aqua-fresca-301363				
https://www.food.com/recipe/fresh-peach-brown-sugar-pie-37425				
https://www.food.com/recipe/simple-caribbean-jerk-chicken-33170				
https://www.food.com/recipe/so-simple-cowboy-caviar-95466				
https://www.food.com/recipe/strawberry-popsicle-375304				
https://www.food.com/recipe/strawberry-shortcake-ice-cream-cake-531330				
https://www.food.com/recipe/oven-crisp-chicken-wings-40497				
https://www.food.com/recipe/broil-a-perfect-steak-165254				
https://www.food.com/recipe/strawberry-shortcake-ice-cream-cake-531330				
https://www.food.com/recipe/award-winning-chili-105865				
https://www.food.com/recipe/broccoli-and-garlic-pasta-117031				
https://www.food.com/recipe/the-best-belgian-waffles-63071				
https://www.food.com/recipe/guacamole-muy-facil-126417				
https://www.food.com/recipe/bourbon-pecan-pork-roast-89226				
https://www.food.com/recipe/fresh-fig-and-feta-salad-with-toasted-walnuts-250866				
https://www.food.com/recipe/roasted-tomatillo-salmon-from-casa-marcela-531711				
https://www.food.com/recipe/raspberry-curd-485743				
https://www.food.com/recipe/ceviche-style-shrimp-and-avocado-tacos-127066				
https://www.food.com/recipe/asian-pesto-485735				
https://www.food.com/recipe/avocado-cilantro-dip-485740				
https://www.food.com/recipe/quick-vegan-pesto-alfredo-sauce-246616				
https://www.food.com/recipe/sage-seasoning-blend-491624				
https://www.food.com/recipe/multigrain-bread-for-abm-amish-friendship-starter-441217				
https://www.food.com/recipe/ceviche-style-shrimp-and-avocado-tacos-127066				
https://www.food.com/recipe/moms-mango-chutney-92982				
https://www.food.com/recipe/chicken-cheddar-pizza-373185				
https://www.food.com/recipe/orange-persian-rice-472866				
https://www.food.com/recipe/sesame-ramen-with-green-peas-243119				
https://www.food.com/recipe/fiesta-shrimp-and-avocado-salsa-124671				
https://www.food.com/recipe/chicken-and-ramen-noodles-436709				
https://www.food.com/recipe/vegan-spaghetti-392908				
https://www.food.com/recipe/ichiban-ramen-179440				
https://www.food.com/recipe/pesto-pasta-salad-187140				
https://www.food.com/recipe/vegan-baked-ziti-229993				
https://www.food.com/recipe/pesto-pasta-197858				
https://www.food.com/recipe/upgraded-ramen-soup-529206				
https://www.food.com/recipe/creole-seasoning-blend-232949				
https://www.food.com/recipe/savory-sage-spice-blend-543794				
https://www.food.com/recipe/everything-seasoning-blend-533053				
https://www.food.com/recipe/butter-tarts-companys-coming-492273				
https://www.food.com/recipe/companys-coming-carrot-puff-bake-179305				
https://www.food.com/recipe/chicken-rotel-with-ramen-noodles-86305				
https://www.food.com/recipe/buttery-poppy-seed-egg-noodles-400536				
https://www.food.com/recipe/hamburger-skillet-dinner-a-fresh-take-on-hamburger-helper-82750				
https://www.food.com/recipe/miso-ramen-337645				
https://www.food.com/recipe/green-spaghetti-259491				
https://www.food.com/recipe/3-cheese-baked-ziti-173394				
https://www.food.com/recipe/easy-baked-ziti-394940				
https://www.food.com/recipe/instant-pot-bolognese-sauce-537074				
https://www.food.com/recipe/rustic-genovese-basil-pesto-pasta-405032				
https://www.food.com/recipe/creamy-pesto-pasta-142220				
https://www.food.com/recipe/lemon-pesto-pasta-312597				
https://www.food.com/recipe/artichoke-pesto-pasta-200749				
https://www.food.com/recipe/basic-ramen-broth-2-241789				
https://www.food.com/recipe/pressure-cooker-hungarian-chicken-170094				
https://www.food.com/recipe/homemade-sausage-seasoning-blend-102718				
https://www.food.com/recipe/steamed-snow-crab-legs-157841				
https://www.food.com/recipe/royal-purple-blueberry-pie-535781				
https://www.food.com/recipe/peach-tomato-salsa-384700				
https://www.food.com/recipe/erics-easy-grilled-chicken-62480				
https://www.food.com/recipe/homemade-sangria-197977				
https://www.food.com/recipe/creamy-cucumber-salad-116236				
https://www.food.com/recipe/italian-ice-276632				
https://www.food.com/recipe/no-bake-chocolate-oatmeal-cookies-23821				
https://www.food.com/recipe/arnold-palmer-bars-532294				
https://www.food.com/recipe/cucumber-and-onion-salad-182382				
https://www.food.com/recipe/broccoli-salad-10733				
https://www.food.com/recipe/baba-ganoush-the-best-in-the-world-67570				
https://www.food.com/recipe/oven-roasted-corn-on-the-cob-177558				
https://www.food.com/recipe/basic-mojito-ingredients-60506				
https://www.food.com/recipe/steak-marinade-quick-and-easy-39145				
https://www.food.com/recipe/raspberry-jam-35192				
https://www.food.com/recipe/butterfinger-pie-29478				
https://www.food.com/recipe/best-ever-bbq-chicken-88453				
https://www.food.com/recipe/awesome-blueberry-muffins-51997				
https://www.food.com/recipe/fajita-seasoning-mix-28011				
https://www.food.com/recipe/pico-de-gallo-83371				
https://www.food.com/recipe/herb-stuffed-pork-tenderloin-59319				
https://www.food.com/recipe/perfect-boiled-shrimp-112889				
https://www.food.com/recipe/snickers-salad-62855				
https://www.food.com/recipe/fish-tacos-26516				
https://www.food.com/recipe/frito-pie-59661				
https://www.food.com/recipe/watermelon-and-cucumber-gazpacho-134088				
https://www.food.com/recipe/fruit-salad-with-pudding-66575				
https://www.food.com/recipe/strawberry-strawberry-pie-from-the-really-good-food-cook-book-14569				
https://www.food.com/recipe/bevs-sauteed-yellow-squash-31371				
https://www.food.com/recipe/easy-coleslaw-dressing-186437				
https://www.food.com/recipe/baked-zucchini-122143				
https://www.food.com/recipe/shish-kabob-marinade-99992				
https://www.food.com/recipe/slow-cooked-bbq-ribs-for-crock-pot-16705				
https://www.food.com/recipe/easy-and-authentic-mexican-guacamole-avocado-dip-62581				
https://www.food.com/recipe/old-fashioned-banana-cream-pie-14979				
https://www.food.com/recipe/southern-sweet-iced-tea-225484				
https://www.food.com/recipe/caramelized-baked-chicken-legs-wings-145038				
https://www.food.com/recipe/fresh-tomato-mozzarella-salad-173875				
https://www.food.com/recipe/italian-pasta-salad-20801				
https://www.food.com/recipe/easy-cream-cheese-brownies-147796				
https://www.food.com/recipe/sweet-pickled-banana-peppers-17254				
https://www.food.com/recipe/baked-beans-65829				
https://www.food.com/recipe/blueberry-ice-cream-91781				
https://www.food.com/recipe/crock-pot-tropical-apple-crisp-330911				
https://www.food.com/recipe/dump-cake-only-3-ingredients-29175				
https://www.food.com/recipe/slow-cooker-banana-upside-down-cake-309037				
https://www.food.com/recipe/cherry-rice-pudding-in-a-slow-cooker-307019				
https://www.food.com/recipe/self-saucing-chocolate-pudding-slow-cooker-516031				
https://www.food.com/recipe/easy-chocolate-covered-raisins-crock-pot-278661				
https://www.food.com/recipe/crock-pot-rice-pudding-18831				
https://www.food.com/recipe/crock-pot-strawberry-cream-cake-525472				
https://www.food.com/recipe/rice-cooker-chocolate-cake-395536				
https://www.food.com/recipe/crock-pot-chocolate-mud-cake-50331				
https://www.food.com/recipe/crock-pot-apple-or-cherry-cobbler-9732				
https://www.food.com/recipe/the-national-trust-heritage-lemon-curd-crock-pot-or-traditional-275052				
https://www.food.com/recipe/slow-cooker-berry-cobbler-349128				
https://www.food.com/recipe/lighter-chocolate-pudding-cake-358810				
https://www.food.com/recipe/crock-pot-apple-pie-311774				
https://www.food.com/recipe/cherry-chocolate-crock-pot-dessert-18757				
https://www.food.com/recipe/crock-pot-blueberry-dump-cake-184879				
https://www.food.com/recipe/spinach-roquefort-salad-169739				
https://www.food.com/recipe/ramen-noodle-salad-69709				
https://www.food.com/recipe/chips-and-salsa-salad-536345				
https://www.food.com/recipe/chunky-blue-cheese-salad-dressing-55514				
https://www.food.com/recipe/thousand-island-dressing-100355				
https://www.food.com/recipe/kittencals-famous-greek-salad-66596				
https://www.food.com/recipe/moroccan-carrot-salad-172367				
https://www.food.com/recipe/chickpea-and-fresh-tomato-toss-51631				
https://www.food.com/recipe/cucumber-cilantro-pasta-salad-322833				
https://www.food.com/recipe/raw-tuscan-kale-salad-with-pecorino-311483				
https://www.food.com/recipe/italian-chopped-salad-in-shells-29329				
https://www.food.com/recipe/sheilas-best-balsamic-dressing-176016				
https://www.food.com/recipe/greek-orzo-salad-with-chickpeas-artichoke-hearts-423444				
https://www.food.com/recipe/cranberry-fruit-coleslaw-101803				
https://www.food.com/recipe/indian-cucumber-salad-172618				
https://www.food.com/recipe/creamy-french-dressing-24655				
https://www.food.com/recipe/tossed-salad-with-mandarin-oranges-132764				
https://www.food.com/recipe/perfect-garden-salad-363077				
https://www.food.com/recipe/broccoli-salad-10135				
https://www.food.com/recipe/55-house-salad-222166				
https://www.food.com/recipe/spinach-salad-with-honey-bacon-dressing-55936				
https://www.food.com/recipe/turkish-salad-82177				
https://www.food.com/recipe/dunkleys-famous-macaroni-salad-44133				
https://www.food.com/recipe/a-salad-of-arugula-rocket-cherry-tomatoes-and-sesame-seed-62550				
https://www.food.com/recipe/traditional-caesar-salad-20449				
https://www.food.com/recipe/california-pizza-kitchen-chopped-salad-112979				
https://www.food.com/recipe/tsr-version-of-outback-ranch-dressing-by-todd-wilbur-70654				
https://www.food.com/recipe/quinoa-tabouli-140618				
https://www.food.com/recipe/moms-potato-salad-32817				
https://www.food.com/recipe/mixed-greens-salad-459021				
https://www.food.com/recipe/cherry-tomato-salad-127186				
https://www.food.com/recipe/squid-salad-or-octopus-salad-japanese-style-336807				
https://www.food.com/recipe/asian-pasta-salad-137204				
https://www.food.com/recipe/belgian-endive-and-apple-salad-with-cranberry-vinaigrette-227117				
https://www.food.com/recipe/broccoli-slaw-292202				
https://www.food.com/recipe/warm-roasted-vegetable-salad-227684				
https://www.food.com/recipe/tsr-version-of-applebees-oriental-chicken-salad-by-todd-wilbur-19253				
https://www.food.com/recipe/lemon-garlic-dijon-vinaigrette-96361				
https://www.food.com/recipe/avocado-lime-salad-207168				
https://www.food.com/recipe/cranberry-couscous-salad-204462				
https://www.food.com/recipe/mexican-chicken-pasta-salad-53495				
https://www.food.com/recipe/tomato-and-bacon-salad-in-bibb-lettuce-cups-180609				
https://www.food.com/recipe/easy-iceberg-wedge-salad-369211				
https://www.food.com/recipe/carlas-chinese-cabbage-parsley-salad-7084				
https://www.food.com/recipe/croutons-93223				
https://www.food.com/recipe/baked-ziti-with-fire-roasted-tomatoes-151234				
https://www.food.com/recipe/birds-famous-macaroni-and-cheese-263081				
https://www.food.com/recipe/easy-shepherds-pie-298417				
https://www.food.com/recipe/weight-watchers-deep-dish-pizza-casserole-224261				
https://www.food.com/recipe/chicken-spaghetti-99423				
https://www.food.com/recipe/chicken-cordon-bleu-casserole-17917				
https://www.food.com/recipe/potsies-creamed-chicken-and-biscuits-casserole-110047				
https://www.food.com/recipe/tamale-pie-240066				
https://www.food.com/recipe/taco-tater-tots-casserole-66023				
https://www.food.com/recipe/easy-way-lasagna-18888				
https://www.food.com/recipe/easiest-beef-enchiladas-ever-17586				
https://www.food.com/recipe/easy-pizza-pasta-casserole-oamc-111734				
https://www.food.com/recipe/chicken-rice-broccoli-cheese-casserole-188079				
https://www.food.com/recipe/brings-back-memories-tuna-casserole-28969				
https://www.food.com/recipe/chicken-cordon-bleu-casserole-108579				
https://www.food.com/recipe/homemade-pasta-3474				
https://www.food.com/recipe/crock-pot-ham-and-beans-96347				
https://www.food.com/recipe/chinese-fried-rice-38748				
https://www.food.com/recipe/lemon-herb-quinoa-65131				
https://www.food.com/recipe/homemade-gnocchi-53874				
https://www.food.com/recipe/authentic-italian-tomato-sauce-92096				
https://www.food.com/recipe/gallo-pinto-costa-rican-rice-and-beans-78747				
https://www.food.com/recipe/jo-mamas-world-famous-spaghetti-22782				
https://www.food.com/recipe/easy-crispy-cornflake-potatoes-151959				
https://www.food.com/recipe/orange-juice-cake-61347				
https://www.food.com/recipe/real-southern-cornbread-51550				
https://www.food.com/recipe/easy-crock-pot-moroccan-chicken-chickpea-and-apricot-tagine-137530				
https://www.food.com/recipe/peanut-butter-overnight-oats-529395				
https://www.food.com/recipe/cranberry-couscous-salad-204462				
https://www.food.com/recipe/biscuits-77417				
https://www.food.com/recipe/chicken-quesadillas-34924				
https://www.food.com/recipe/pumpkin-spice-overnight-oats-537205				
https://www.food.com/recipe/five-or-more-ingredient-garlic-quinoa-121064				
https://www.food.com/recipe/marinated-chickpea-and-artichoke-salad-with-feta-88437				
https://www.food.com/recipe/creamy-garlic-penne-pasta-43023				
https://www.food.com/recipe/creamy-cajun-chicken-pasta-39087				
https://www.food.com/recipe/dried-cranberries-and-squash-138140				
https://www.food.com/recipe/rice-pudding-9160				
https://www.food.com/recipe/baja-black-beans-corn-and-rice-55768				
https://www.food.com/recipe/freezer-ready-cornflake-chicken-fingers-63217				
https://www.food.com/recipe/olive-garden-fettuccine-alfredo-8596				
https://www.food.com/recipe/the-original-rice-krispies-treats-squares-96111				
https://www.food.com/recipe/thai-coconut-rice-63446				
https://www.food.com/recipe/chicken-rice-casserole-118072				
https://www.food.com/recipe/garlic-potatoes-13380				
https://www.food.com/recipe/mexican-red-rice-138356				
https://www.food.com/recipe/crescent-city-red-beans-rice-crock-pot-373619				
https://www.food.com/recipe/oatmeal-raisin-cookies-35813				
https://www.food.com/recipe/best-ever-tuna-noodle-casserole-199579				
https://www.food.com/recipe/pancakes-25690				
https://www.food.com/recipe/emeril-s-new-orleans-style-red-beans-and-rice-316629				
https://www.food.com/recipe/simple-tomato-sandwich-98724				
https://www.food.com/recipe/spinach-tomato-and-feta-quiche-33454				
https://www.food.com/recipe/frito-lay-bean-dip-68861				
https://www.food.com/recipe/chocolate-chip-muffins-9326				
https://www.food.com/recipe/baked-beans-39478				
https://www.food.com/recipe/baked-macaroni-and-cheese-with-stewed-tomatoes-28261				
https://www.food.com/recipe/beef-and-bean-burritos-131176				
https://www.food.com/recipe/flour-tortilla-recipe-204109				
https://www.food.com/recipe/quick-homemade-tomato-soup-12438				
https://www.food.com/recipe/cranberry-oatmeal-muffins-37861				
https://www.food.com/recipe/best-banana-bread-2886				
https://www.food.com/recipe/basic-batter-waffles-110683				
https://www.food.com/recipe/jiffy-corn-casserole-5-ingredients-easy-delicious-313521				
https://www.food.com/recipe/popcorn-balls-101459				
https://www.food.com/recipe/tuna-casserole-28468				
https://www.food.com/recipe/sweet-potato-yam-casserole-with-marshmallows-104150				
https://www.food.com/recipe/chicken-enchiladas-33076				
https://www.food.com/recipe/creamed-corn-145165				
https://www.food.com/recipe/overnight-french-toast-casserole-42292				
https://www.food.com/recipe/quinoa-tahini-cookies-159837				
https://www.food.com/recipe/bacon-egg-and-cheddar-scones-92160				
https://www.food.com/recipe/chicken-rice-and-spices-bake-105104				
https://www.food.com/recipe/arabian-pita-bread-17977				
https://www.food.com/recipe/award-winning-butter-tarts-14756				
https://www.food.com/recipe/simply-oven-baked-pork-chops-and-rice-142542				
https://www.food.com/recipe/caramel-puff-corn-80247				
https://www.food.com/recipe/heart-shaped-dried-cherry-and-chocolate-chip-scones-149024				
https://www.food.com/recipe/curried-quinoa-salad-with-cranberries-122057				
https://www.food.com/recipe/frito-corn-salad-88251				
https://www.food.com/recipe/quinoa-pudding-137505				
https://www.food.com/recipe/cranberry-scones-39563				
https://www.food.com/recipe/buttery-bread-machine-rolls-65340				
https://www.food.com/recipe/no-bake-rice-krispies-peanut-butter-granola-bars-lower-fat-73754				
https://www.food.com/recipe/the-best-pasta-salad-8794				
https://www.food.com/recipe/microwave-chocolate-mug-brownie-349246				
https://www.food.com/recipe/toasted-ravioli-26820				
https://www.food.com/recipe/red-velvet-mug-cake-527599				
https://www.food.com/recipe/chicken-noodle-soup-20755				
https://www.food.com/recipe/orzo-with-parmesan-and-basil-79431				
https://www.food.com/recipe/soft-snickerdoodle-cookies-97496				
https://www.food.com/recipe/honey-granola-90046				
https://www.food.com/recipe/trail-mix-granola-muffins-162210				
https://www.food.com/recipe/spiced-sweet-roasted-red-pepper-feta-hummus-38846				
https://www.food.com/recipe/green-olive-tapenade-46486				
https://www.food.com/recipe/easy-banana-muffins-8603				
https://www.food.com/recipe/mediterranean-chicken-breasts-with-avocado-tapenade-178249				
https://www.food.com/recipe/benihana-japanese-fried-rice-71472				
https://www.food.com/recipe/grape-jelly-meatballs-72826				
https://www.food.com/recipe/peanut-butter-no-bake-cookies-129040				
https://www.food.com/recipe/worlds-best-butter-cookies-50684				
https://www.food.com/recipe/beer-bread-73440				
https://www.food.com/recipe/montreal-steak-spice-rub-379398				
https://www.food.com/recipe/cinnamon-swirl-blondie-513601				
https://www.food.com/recipe/brioche-51546				
https://www.food.com/recipe/one-pot-cheesy-chicken-and-noodles-334688				
https://www.food.com/recipe/the-ultimate-breakfast-frittata-25219				
https://www.food.com/recipe/easy-chicken-tortilla-soup-213068				
https://www.food.com/recipe/cheeseburger-quiche-476322				
https://www.food.com/recipe/simple-shrimp-stir-fry-277101				
https://www.food.com/recipe/daughters-dinner-in-a-dash-student-one-pot-chicken-212153				
https://www.food.com/recipe/pork-chops-and-rice-142609				
https://www.food.com/recipe/beef-roni-208089				
https://www.food.com/recipe/tortellini-tomato-spinach-soup-35988				
https://www.food.com/recipe/mac-and-cheese-quesadillas-271998				
https://www.food.com/recipe/salmon-pilaf-with-green-onions-415243				
https://www.food.com/recipe/penne-alla-vodka-86248				
https://www.food.com/recipe/italian-sausage-tomato-and-cream-with-bow-ties-135281				
https://www.food.com/recipe/easy-lasagna-rolls-131058				
https://www.food.com/recipe/ranch-and-avocado-pasta-salad-322546				
https://www.food.com/recipe/macaroni-with-fontina-and-gorgonzola-cheeses-217322				
https://www.food.com/recipe/simple-pasta-with-parmesan-241627				
https://www.food.com/recipe/lemon-chicken-linguine-303846				
https://www.food.com/recipe/pasta-with-sausage-tomatoes-and-cream-32844				
https://www.food.com/recipe/delicioso-antipasto-pasta-salad-241077				
https://www.food.com/recipe/baked-spaghetti-26771				
https://www.food.com/recipe/sweet-italian-sausage-with-penne-pasta-360549				
https://www.food.com/recipe/chicken-taco-pasta-202327				
https://www.food.com/recipe/my-super-simple-spaghetti-sauce-266734				
https://www.food.com/recipe/balsamic-chicken-pasta-189905				
https://www.food.com/recipe/buffalo-chicken-pasta-334986				
https://www.food.com/recipe/i-hate-ricotta-meat-2-cheese-stuffed-shells-56846				
https://www.food.com/recipe/lemon-chicken-pasta-222310				
https://www.food.com/recipe/creamy-ramen-noodles-133448				
https://www.food.com/recipe/baked-cavatelli-252171				
https://www.food.com/recipe/the-simplest-tomato-sauce-ever-marcella-hazan-273976				
https://www.food.com/recipe/stuffed-pasta-shells-for-meat-lovers-199963				

`;

let urlList = urls.split('https://').map(url => url.trim()).filter(url => url !== '');



const uniqueFilteredList = [...new Set(urlList)].filter(item => item.includes("www.food.com"));

export default uniqueFilteredList.slice(330, 360);


