import React from "react";

export const SimpleFounders = () => {

  let founders =
  "https://lecuckoo.blob.core.windows.net/lecuckoo/founders.mp4";

  return (
    <div className="SimpleFounder">
      <video
      className="simple__bg"
      controls = "true"
      autoPlay
      loop
      playsInline
      preload="metadata"
      >
        <source src={founders} type="video/mp4"></source>
      </video>
    </div>
  );
};
