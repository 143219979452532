import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import "./RecipieEditor.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NavBar } from "../navbar/NavBar";
import { RecipeTesting } from "../recipetesting/RecipeTesting";
import { GptReview } from "./gpt/GptReview";
import { Ingredients } from "./ingredients/Ingredients";
import { Directions } from "./directions/Directions";
import { Nutritionals } from "./nutritional/Nutritionals";
import { PublishPopup } from "./publishPopup/PublishPopup";
import brandlogo from "../../assets/lecuckoologo1.png";
import loadIngredients from "../../Realdatas.js";
import axios from "axios";

export const RecipieEditor = () => {
  const [isEdit, setEdit] = useState(false);
  const [directions, setDirections] = useState([]);
  const [directionText, setDirectionText] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [personalNote, setPersonalNote] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [openTestForm, setOpenTestForm] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [testingFormData, setTestingFormData] = useState([]);
  const [publishData, setPublishData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [recipeToEdit, setRecipeToEdit] = useState(null);

  const [gptResponseLines, setGptResposnseLines] = useState("");

  let REACT_APP_OPENAI_API_KEY = "ea5191e99c364200975b027a5db8cd56";

  const [openpublishPopup, setPublishPopup] = useState(false);

  const [ingredientInfo, setIngredientInfo] = useState({
    name: "",
    amount: "",
    unit: "",
  });

  const intialNutrtions = {
    calories: 0,
    carbohydrates: 0,
    protein: 0,
    cholesterol: 0,
    sodium: 0,
    potassium: 0,
    fiber: 0,
    sugar: 0,
    vitaminA: 0,
    calcium: 0,
    iron: 0,
  };
  const [nutritionalValues, setNutritionalValues] = useState(intialNutrtions);

  const [formData, setFormData] = useState({
    recipeName: "",
    versionNumber: "",
    prepTime: "",
    recipeDescription: "",
    servingSize: "",
    totalCookTime: "",
  });

  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("importId") || urlParams.get("id");

  useEffect(() => {
    const email = localStorage.getItem("email");
    setIsLoggedIn(email !== null);
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }
    setEdit(true);

    const recipeData = JSON.parse(sessionStorage.getItem("recipes")) || [];

    const pdfRecipeData =
      JSON.parse(sessionStorage.getItem("uploadedRecipeData")) || [];

    const mergedData = [...recipeData, ...pdfRecipeData];

    const recipeToEdit = mergedData.find(
      (recipe) => recipe.id === parseInt(id)
    );

    if (recipeToEdit) {
      setFormData(recipeToEdit);
      setIngredients(recipeToEdit.ingredients || []);
      setDirections(recipeToEdit.directions || []);
      setPersonalNote(recipeToEdit.personalNote || "");
      setNutritionalValues(recipeToEdit.nutritionalFacts || {});
      setRecipeToEdit(recipeToEdit);
    }
  }, [id]);

  const realLoadedDatas = Object.entries(loadIngredients).flatMap(
    ([name, details]) => ({
      name,
      ...details,
    })
  );

  const roundToDecimalPlaces = (num, decimalPlaces) => {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(num * multiplier) / multiplier;
  };

  const decimalPlaces = 2;

  function normalizeString(str) {
    return str.toLowerCase().replace(/\s+/g, "");
  }
  const checkIngredientExistence = (ingredients) => {
    let accumulatedData = { ...intialNutrtions };
    let allMatchedData = [];

    let anythingMatch = false;

    for (const ingredient of ingredients) {
      if (ingredient.type === "ingredient") {
        for (const loadedData of realLoadedDatas) {
          if (
            normalizeString(ingredient.name) ===
            normalizeString(loadedData.name)
          ) {
            anythingMatch = true;
            allMatchedData.push(loadedData);
            for (const prop in loadedData) {
              if (prop in accumulatedData) {
                accumulatedData[prop] += ingredient.amount * loadedData[prop];
              } else {
                accumulatedData[prop] = ingredient.amount * loadedData[prop];
              }
            }

            for (const prop in accumulatedData) {
              if (typeof accumulatedData[prop] === "number") {
                accumulatedData[prop] = roundToDecimalPlaces(
                  accumulatedData[prop],
                  decimalPlaces
                );
              }
            }
            break;
          }
        }
      }
    }

    const filteredData = Object.keys(accumulatedData)
      .filter((key) => key in nutritionalValues)
      .reduce((obj, key) => {
        obj[key] = accumulatedData[key];
        return obj;
      }, {});

    if (anythingMatch || ingredients.length === 0)
      setNutritionalValues(filteredData);
  };

  const resetForm = () => {
    setFormData({
      recipeName: "",
      versionNumber: "",
      prepTime: "",
      recipeDescription: "",
      servingSize: "",
      totalCookTime: "",
      ingredients: [],
      directions: [],
      personalNote: "",
      nutritionalFacts: {},
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name !== "recipeName" && name !== "recipeDescription") {
      if (!isNaN(updatedValue)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: updatedValue,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: updatedValue,
      }));
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = "required";
      }
    });
    console.log("errors", errors);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    setEdit(true);

    const recipeData = JSON.parse(sessionStorage.getItem("recipes")) || [];
    const pdfRecipeData =
      JSON.parse(sessionStorage.getItem("uploadedRecipeData")) || [];
    const mergedData = [...recipeData, ...pdfRecipeData];

    const recipeToEdit = mergedData.find(
      (recipe) => recipe.id === parseInt(id)
    );

    if (recipeToEdit) {
      setFormData(recipeToEdit);
      setIngredients(recipeToEdit.ingredients || []);
      setDirections(recipeToEdit.directions || []);
      setPersonalNote(recipeToEdit.personalNote || "");
      setNutritionalValues(recipeToEdit.nutritionalFacts || {});
    }
  }, [id]);

  const submitRecipes = () => {
    const isValid = validateForm();
    if (!isLoggedIn) {
      navigate("/register");
      return;
    } else {
      if (isValid) {
        if (
          formData.recipeName &&
          formData.versionNumber &&
          formData.prepTime &&
          formData.recipeDescription &&
          formData.servingSize &&
          formData.totalCookTime &&
          ingredients.length > 0 &&
          directions.length > 0 &&
          Object.keys(nutritionalValues).length > 0 &&
          personalNote
        ) {
          const urlParams = new URLSearchParams(window.location.search);
          const id = urlParams.get("importId")
            ? Date.now()
            : formData.id || Date.now();

          const isImportRevised = urlParams.get("importId")
            ? "( Revised )"
            : "";
          const existingRecipes =
            JSON.parse(sessionStorage.getItem("recipes")) || [];
          const newRecipe = {
            id: id,
            stage: formData.stage || "InProgress",
            recipeName: formData.recipeName + isImportRevised,
            versionNumber: formData.versionNumber,
            prepTime: formData.prepTime,
            recipeDescription: formData.recipeDescription,
            servingSize: formData.servingSize,
            status: formData.status || "Not Published",
            totalCookTime: formData.totalCookTime,
            ingredients: ingredients,
            directions: directions,
            personalNote: personalNote,
            nutritionalFacts: nutritionalValues,
          };

          const updatedRecipeIndex = existingRecipes.findIndex(
            (recipe) => recipe.id === formData.id
          );

          if (updatedRecipeIndex !== -1) {
            existingRecipes[updatedRecipeIndex] = { ...newRecipe };
          } else {
            existingRecipes.push({ ...newRecipe });
          }

          sessionStorage.setItem("recipes", JSON.stringify(existingRecipes));

          resetForm();

          toast.success("Saved Successfully", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            bodyClassName: "single__line__toast",
          });
          navigate("/dashboard");
        } else {
          alert("Please fill all required fields");
        }
      } else {
        console.log(
          "Form has errors, please fill all required fields correctly."
        );
      }
    }
  };

  const fetchReviewLines = async (gptReviewResponse) => {
    try {
      const response = await axios.post(
        "https://lecuckoo-ai.openai.azure.com/openai/deployments/lecuckoov1/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            {
              role: "system",
              content:
                "I'd like you to take the form of a helpful AI sous chef called Luc AI. You will be providing top quality chef-reviewed recipe feedback based on recipe JSON data that you will be provided. Please format your response to give me a straightforward, polite, and helpful. Please format your review in sequential order, bullet points. Focus on spotlighting improvements such as grammar, spell check, the effectiveness of ingredients order, directions, and nutritional information. Example scenarios: If the user's ingredients are out of order, consider improving the order of ingredients. If the user's directions sound too unclear, consider breaking down some steps further to increase clarity. If a recipe's name or description does not match the fidelity of proper recipes, suggest edits. Start your response with a general remark and seamlessly transition to the feedback list. End your response with an overall positive remark to encourage the chef to continue improvement. Make your response no longer than 50 words. Start by greeting yourself to the user and wait for the recipe data to be sent to you. Do not explicitly mention that you're going to input JSON data, as it does not feel personable as Luc AI should be. Begin your review once you've received the recipe data only. Please take inspiration from the following example Luc AI output: This hamburger recipe is clear and well-structured. Improve by: 1. Correcting sTaek to Steak in first ingredient. 2. Standardizing measurements. 3. Detailing spice amounts. 4. Specifying medium-rare and well-done temperatures. 5. Enhancing meat notes and combining make-ahead instructions. Overall, it is comprehensive and user-friendly. Enjoy crafting the perfect burger!",
            },
            {
              role: "user",
              content: `Here is the detailed recipe content: ${gptReviewResponse}`,
            },
          ],
          max_tokens: 800,
          temperature: 0.7,
          frequency_penalty: 0,
          presence_penalty: 0,
          top_p: 0.95,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": REACT_APP_OPENAI_API_KEY,
          },
        }
      );

      const reviewContent = response.data.choices[0].message.content;
      setGptResposnseLines(reviewContent.split("\n"));
    } catch (error) {
      console.error("Error calling OpenAI API", error);
      setGptResposnseLines([
        "An error occurred while trying to generate the review.",
      ]);
    } finally {
      setLoading(false);
    }
  };

  console.log(":::}}}}", gptResponseLines);

  const respoGpt = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("importId")
      ? Date.now()
      : formData.id || Date.now();

    const isImportRevised = urlParams.get("importId") ? "( Revised )" : "";

    const newRecipe = {
      id: id,
      stage: formData.stage || "InProgress",
      recipeName: formData.recipeName + isImportRevised,
      versionNumber: formData.versionNumber,
      prepTime: formData.prepTime,
      recipeDescription: formData.recipeDescription,
      servingSize: formData.servingSize,
      status: formData.status || "Not Published",
      totalCookTime: formData.totalCookTime,
      ingredients: ingredients,
      directions: directions,
      personalNote: personalNote,
      nutritionalFacts: nutritionalValues,
    };

    setLoading(true);
    await fetchReviewLines(JSON.stringify(newRecipe));
  };

  const handleFormSubmission = (
    isValid,
    stageValue,
    setDataFunction,
    setOpenFunction,
    navigateCallback,
    currentStatus
  ) => {
    if (!isLoggedIn) {
      navigateCallback("/register");
      return;
    }

    if (isValid) {
      if (
        formData.recipeName &&
        formData.versionNumber &&
        formData.prepTime &&
        formData.recipeDescription &&
        formData.servingSize &&
        formData.totalCookTime &&
        ingredients.length > 0 &&
        directions.length > 0 &&
        Object.keys(nutritionalValues).length > 0 &&
        personalNote
      ) {
        const existingRecipes =
          JSON.parse(sessionStorage.getItem("recipes")) || [];
        const existingRecipeIndex = existingRecipes.findIndex(
          (recipe) => recipe.id === formData.id
        );

        const newRecipe = {
          id: formData.id || Date.now(),
          stage: stageValue,
          recipeName: formData.recipeName,
          versionNumber: formData.versionNumber,
          prepTime: formData.prepTime,
          status: currentStatus,
          recipeDescription: formData.recipeDescription,
          servingSize: formData.servingSize,
          totalCookTime: formData.totalCookTime,
          ingredients: ingredients,
          directions: directions,
          nutritionalFacts: nutritionalValues,
          personalNote: personalNote,
        };

        if (existingRecipeIndex !== -1) {
          existingRecipes[existingRecipeIndex] = { ...newRecipe };
        } else {
          existingRecipes.push(newRecipe);
        }

        sessionStorage.setItem("recipes", JSON.stringify(existingRecipes));

        setDataFunction({ ...newRecipe });

        setOpenFunction((prevState) => !prevState);
      } else {
        alert("Please fill all required fields");
      }
    } else {
      console.log(
        "Form has errors, please fill all required fields correctly."
      );
    }
  };

  const submitForTesting = () => {
    const isValid = validateForm();
    handleFormSubmission(
      isValid,
      "InReview",
      setTestingFormData,
      setOpenTestForm,
      navigate,
      "Not Published"
    );
  };

  const submitForPublish = () => {
    const isValid = validateForm();
    handleFormSubmission(
      isValid,
      "Serving",
      setPublishData,
      setPublishPopup,
      navigate,
      "Published"
    );
  };

  const settestingOpen = () => {
    const isValid = validateForm();
    if (isValid) {
      if (
        formData.recipeName &&
        formData.versionNumber &&
        formData.prepTime &&
        formData.recipeDescription &&
        formData.servingSize &&
        formData.totalCookTime &&
        ingredients.length > 0 &&
        directions.length > 0 &&
        Object.keys(nutritionalValues).length > 0 &&
        personalNote
      ) {
        setOpenTestForm((prevState) => !prevState);
      } else {
        alert("Please fill all required fields");
      }
    } else {
      alert("Please fill all required fields");
    }
  };
  const setPublishOpen = () => {
    const isValid = validateForm();
    if (isValid) {
      if (
        formData.recipeName &&
        formData.versionNumber &&
        formData.prepTime &&
        formData.recipeDescription &&
        formData.servingSize &&
        formData.totalCookTime &&
        ingredients.length > 0 &&
        directions.length > 0 &&
        Object.keys(nutritionalValues).length > 0 &&
        personalNote
      ) {
        setPublishPopup((prevState) => !prevState);
      } else {
        alert("Please fill all required fields");
      }
    } else {
      alert("Please fill all required fields");
    }
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDrop = (e, targetIndex, ingredients, setIngredients) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const updatedItems = [...ingredients];
    const draggedItem = updatedItems[dragIndex];
    updatedItems.splice(dragIndex, 1);
    updatedItems.splice(targetIndex, 0, draggedItem);
    setIngredients(updatedItems);
  };

  // const updateNutritionalValues = (item, isDeleted = false) => {
  //   const amountMultiplier = isDeleted ? -1 : 1;

  //   setNutritionalValues((prevValues) => ({
  //     calories: Math.round(
  //       prevValues.calories + item.amount * 0.08 * amountMultiplier
  //     ),
  //     carbohydrates: Math.round(
  //       prevValues.carbohydrates + item.amount * 0.05 * amountMultiplier
  //     ),
  //     protein: Math.round(
  //       prevValues.protein + item.amount * 0.2 * amountMultiplier
  //     ),
  //     cholesterol: Math.round(
  //       prevValues.cholesterol + item.amount * 0.7 * amountMultiplier
  //     ),
  //     sodium: Math.round(
  //       prevValues.sodium + item.amount * 0.9 * amountMultiplier
  //     ),
  //     potassium: Math.round(
  //       prevValues.potassium + item.amount * 0.9 * amountMultiplier
  //     ),
  //     fiber: Math.round(
  //       prevValues.fiber + item.amount * 0.95 * amountMultiplier
  //     ),
  //     sugar: Math.round(prevValues.sugar + item.amount * 1 * amountMultiplier),
  //     vitaminA: Math.round(
  //       prevValues.vitaminA + item.amount * 8 * amountMultiplier
  //     ),
  //     calcium: Math.round(
  //       prevValues.calcium + item.amount * 2 * amountMultiplier
  //     ),
  //     iron: Math.round(prevValues.iron + item.amount * 1 * amountMultiplier),
  //   }));
  // };

  const clearItemFields = () => {
    setIngredientInfo({ name: "", amount: "", unit: "" });
    setDirectionText("");
  };

  const deleteRecipes = (ingredients, setIngredients, index) => {
    const updatedIngridents = ingredients.filter((item, i) => i !== index);
    setIngredients(updatedIngridents);
    checkIngredientExistence(updatedIngridents);
  };

  return (
    <>
      <NavBar />
      <img className="dashboard__logo" src={brandlogo} alt="img" />
      <div className="recipe__editor__controls small">
        <div className="header__btn" onClick={submitRecipes}>
          Save & Exit
        </div>

        <Link to="/recipe-feedback" className="header__btn">
          View Feedback
        </Link>

        {formData.stage !== "Serving" && formData.stage !== "InReview" && (
          <div onClick={settestingOpen} className="header__btn">
            Submit for testing
          </div>
        )}

        {formData.stage === "Serving" ? null : (
          <div className="header__btn" onClick={setPublishOpen}>
            Publish
          </div>
        )}
      </div>

      <div className="editor__title medium">Create Recipe</div>
      <div className="recipieEditor">
        <div className="left__container">
          <GptReview
            loading={loading}
            setLoading={setLoading}
            gptResponseLines={gptResponseLines}
            respoGpt={respoGpt}
          />
        </div>
        <div className="right__container scrollbar">
          {openTestForm && (
            <div className="testing__modal">
              <RecipeTesting
                openTestForm={setOpenTestForm}
                testingFormData={testingFormData}
                setTestingFormData={setTestingFormData}
                submitForTesting={submitForTesting}
              />
            </div>
          )}

          {openTestForm && (
            <div
              className="popup__overlay"
              onClick={() => setTestingFormData(!openTestForm)}
            ></div>
          )}
          {openpublishPopup && (
            <div
              className="popup__overlay"
              onClick={() => setPublishPopup(!openpublishPopup)}
            ></div>
          )}
          {openpublishPopup && (
            <PublishPopup
              publishData={publishData}
              setPublishData={setPublishData}
              openpublishPopup={openpublishPopup}
              setPublishPopup={setPublishPopup}
              submitForPublish={submitForPublish}
            />
          )}

          {/*  Recipe inputs */}
          <div className="inputs__and__note">
            <div className="recipe__data">
              <div className="grid1">
                <div className="recipe__inputs">
                  <input
                    type="text"
                    id="recipeName"
                    name="recipeName"
                    placeholder=" "
                    value={formData.recipeName}
                    onChange={handleInputChange}
                  />
                  <div className="input__label" htmlFor="recipeName">
                    Enter your recipe name
                  </div>
                  {formErrors.recipeName && (
                    <div className="error__msg">{formErrors.recipeName}</div>
                  )}
                </div>

                <div className="recipe__inputs">
                  <input
                    type="text"
                    id="versionNumber"
                    name="versionNumber"
                    className="small__width"
                    placeholder=" "
                    value={formData.versionNumber}
                    onChange={handleInputChange}
                  />
                  <div className="input__label" htmlFor="versionNumber">
                    Version
                  </div>
                  {formErrors.versionNumber && (
                    <div className="error__msg">{formErrors.versionNumber}</div>
                  )}
                </div>

                <div className="recipe__inputs">
                  <input
                    className="small__width"
                    type="text"
                    id="prepTime"
                    name="prepTime"
                    placeholder=" "
                    value={formData.prepTime}
                    onChange={handleInputChange}
                  />
                  <div class="input__label">Prep (min)</div>
                  {formErrors.prepTime && (
                    <div className="error__msg">{formErrors.prepTime}</div>
                  )}
                </div>
              </div>
              <div className="grid2">
                <div className="recipe__inputs">
                  <textarea
                    type="text"
                    className="small__width"
                    id="recipeDescription"
                    name="recipeDescription"
                    placeholder=" "
                    value={formData.recipeDescription}
                    onChange={handleInputChange}
                  />
                  <div className="input__label" htmlFor="recipeName">
                    Enter your recipe description
                  </div>
                  {formErrors.recipeDescription && (
                    <div className="error__msg">
                      {formErrors.recipeDescription}
                    </div>
                  )}
                </div>

                <div className="grid2__extended">
                  <div className="recipe__inputs">
                    <input
                      type="text"
                      id="servingSize"
                      name="servingSize"
                      className="small__width"
                      placeholder=" "
                      value={formData.servingSize}
                      onChange={handleInputChange}
                    />
                    <div className="input__label" htmlFor="servingSize">
                      Serving
                    </div>
                    {formErrors.servingSize && (
                      <div className="error__msg">{formErrors.servingSize}</div>
                    )}
                  </div>

                  <div className="recipe__inputs">
                    <input
                      type="text"
                      id="totalCookTime"
                      className="small__width"
                      name="totalCookTime"
                      placeholder=" "
                      value={formData.totalCookTime}
                      onChange={handleInputChange}
                    />
                    <div className="input__label" htmlFor="totalCookTime">
                      Cook (min)
                    </div>
                    {formErrors.totalCookTime && (
                      <div className="error__msg">
                        {formErrors.totalCookTime}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid3">
                <div className="recipe__inputs">
                  <input
                    type="text"
                    className="small__width"
                    id="hashtags"
                    name="hashtags"
                    placeholder=" "
                    // value={formData.recipeDescription}
                    // onChange={handleInputChange}
                  />
                  <div className="input__label" htmlFor="Add Hashtags">
                    Add Hashtags
                  </div>
                  {/* {formErrors.recipeDescription && (
                  <div className="error__msg">
                    {formErrors.recipeDescription}
                  </div>
                )} */}
                </div>
              </div>
            </div>
            <div className="personal__note__section">
              <div className="title">Personal Notes</div>
              <div className="notes__field">
                <textarea
                  id="personalNoteTextarea"
                  className="scrollbar"
                  rows="9"
                  cols="35"
                  placeholder="Enter your text here"
                  value={personalNote}
                  onChange={(e) => setPersonalNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>

          {/* Adding Ingredients  */}

          <div className="recipe__adding__container">
            <Ingredients
              // personalNote={personalNote}
              // setPersonalNote={setPersonalNote}
              checkIngredientExistence={checkIngredientExistence}
              realLoadedDatas={realLoadedDatas}
              setIngredientInfo={setIngredientInfo}
              ingredientInfo={ingredientInfo}
              ingredients={ingredients}
              setIngredients={setIngredients}
              // updateNutritionalValues={updateNutritionalValues}
              clearItemFields={clearItemFields}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
              deleteRecipes={deleteRecipes}
            />
          </div>

          {/* Adding Directions */}

          <div className="recipe__adding__container">
            <Directions
              directions={directions}
              setDirections={setDirections}
              directionText={directionText}
              setDirectionText={setDirectionText}
              handleDragStart={handleDragStart}
              clearItemFields={clearItemFields}
              handleDrop={handleDrop}
              deleteRecipes={deleteRecipes}
            />
          </div>
        </div>
        
        <div className="nutritional_section">
          <Nutritionals nutritionalValues={nutritionalValues} />
          <div className="nutrition_values">
              U.S. Department of Agriculture,
              Agricultural Research Service.
              FoodData Central [Internet], Beltsville
              (MD): Beltsville Human Nutrition
              Research Center; [updated 2023 Oct;
              cited 2024 Jul 5]. Available from:
              https://fdc.nal.usda.gov/
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};
