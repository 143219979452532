import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { HomePage } from "./Components/HomePage/HomePage";
import { CreateAccount } from "./Auth/CreateAccount/CreateAccount";
import { UserLogin } from "./Auth/UserLogin/UserLogin";
import { DashBoard } from "./Components/Dashboard/DashBoard";
import { RecipieEditor } from "./Components/recipieEditor/RecipieEditor";
import "./App.css";
import { StillCooking } from "./Components/stillCooking/StillCooking";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RecipeTesting } from "./Components/recipetesting/RecipeTesting";
import { ViewFeedback } from "./Components/feedbackPage/ViewFeedback";
import { PublishPopup } from "./Components/recipieEditor/publishPopup/PublishPopup";
import { ViewRecipe } from "./Components/Dashboard/recoipeTables/viewRecipe/ViewRecipe";
import TestingComponent from "./Components/TestingComponent/TestingComponent";
import { Loader } from "./Loader";
import { SimpleDemo } from "./demo/SimpleDemo";
import { SimpleFounder } from "./founder/SimpleFounder";
import { SimpleFounders } from "./founders/SimpleFounders";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const recipeUrl = `https://www.food.com/recipe/chicken-tikka-masala-394639`;

  useEffect(() => {
    const userEmail = localStorage.getItem("email");
    setIsLoggedIn(userEmail !== null);
  }, []);

  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/register"
            element={isLoggedIn ? <Navigate to="/" /> : <CreateAccount />}
          />
          <Route
            path="/login"
            element={!isLoggedIn && <UserLogin setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/dashboard/view-recipe/:id" element={<ViewRecipe />} />
          <Route path="/recipe-feedback" element={<ViewFeedback />} />
          <Route path="/recipe-editor" element={<RecipieEditor />} />
          <Route path="/still-cooking" element={<StillCooking />} />
          <Route path="/recipe-testing" element={<RecipeTesting />} />
          <Route path="/publishPopup" element={<PublishPopup />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/test" element={<TestingComponent url={recipeUrl} />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/demo" element={<SimpleDemo />} />
          <Route path="/founder" element={<SimpleFounder />} />
          <Route path="/founders" element={<SimpleFounders />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
