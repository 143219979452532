import React from 'react'

export const SimpleFounder = () => {

  let founderUrl =
  "https://lecuckoo.blob.core.windows.net/lecuckoo/founder.mp4";


  return (
    <div className="SimpleFounder">
    <video
      className="simple__bg"
      controls = "true"
      autoPlay
      loop
      playsInline
      preload="metadata"
    >
      <source src={founderUrl} type="video/mp4"></source>
    </video>
  </div>
  )
}
