const dashBoardData = [
  [
    {
      stage: "InProgress",
      id: 1715085414148,
      recipeName: "1/4 lb Burger",
      versionNumber: "12",
      prepTime: "60",
      recipeDescription:
        "Onions are consumed in a variety of preparations, including raw, pickled, fried, sauteed,",
      status: "Not Published",
      servingSize: "34",
      totalCookTime: "60",
      ingredients: [
        {
          type: "header",
          name: "Dry Ingredients",
        },
        {
          type: "ingredient",
          name: "Worchester sauce",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "Hamburger buns",
          amount: 1,
          unit: "g",
        },
        {
          type: "header",
          name: "Wet Ingredients",
        },
        {
          type: "ingredient",
          name: "Sliced Beefsteak tomatoes",
          amount: 1,
          unit: "g",
        }
      ],
      directions: [
        {
          type: "header",
          name: "Prepare the Dough",
        },
        {
          type: "direction",
          name: "Preheat the oven to 350°F (175°C).",
        },
        {
          type: "header",
          name: "Bake the Cookies",
        },
        {
          type: "direction",
          name: "Scoop dough onto baking sheets.",
        },
      ],
      personalNote:
        "This recipe is a family favorite and always a hit at gatherings. Feel free to customize the ingredients to your taste preferences. Enjoy making and sharing these delicious cookies!",
      nutritionalFacts: {
        calories: 43.5,
        carbohydrates: 0,
        protein: 1.27,
        cholesterol: 0.0047,
        sodium: 0.06387,
        potassium: 0.0521,
        fiber: 2.75,
        sugar: 1.17,
        vitaminA: 0.000024,
        calcium: 0.0481,
        iron: 0.0003,
      },
    },
    {
      stage: "InProgress",
      id: 1715086252842,
      recipeName: "Gochujang Chicken Wings",
      versionNumber: "2312",
      prepTime: "12321",
      recipeDescription:
        "It is picked at the moment it turns from green to red during its ripening process and is then dried naturally",
      status: "Not Published",
      servingSize: "213123",
      totalCookTime: "312312",
      ingredients: [
        {
          type: "header",
          name: "Dry Ingredients",
        },
        {
          type: "ingredient",
          name: "Baking Powder",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "Salt",
          amount: 1,
          unit: "g",
        },
        {
          type: "header",
          name: "Wet Ingredients",
        },
        {
          type: "ingredient",
          name: "Freshly ground black pepper",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "garlic powder",
          amount: 1,
          unit: "g",
        },
      ],
      directions: [
        {
          type: "header",
          name: "Prepare the Dough",
        },
        {
          type: "direction",
          name: "Preheat the oven to 350°F (175°C).",
        },
        {
          type: "header",
          name: "Bake the Cookies",
        },
        {
          type: "direction",
          name: "Scoop dough onto baking sheets.",
        },
      ],
      personalNote:
        "This recipe is a family favorite and always a hit at gatherings. Feel free to customize the ingredients to your taste preferences. Enjoy making and sharing these delicious cookies!",
      nutritionalFacts: {
        calories: 7.5,
        carbohydrates: 0,
        protein: 0.3,
        cholesterol: 0,
        sodium: 0.58515,
        potassium: 0.3704,
        fiber: 0.3,
        sugar: 0,
        vitaminA: 0.0000002,
        calcium: 0.1551,
        iron: 0.0004,
      },
    },
  ],
  [
    {
      stage: "InReview",
      id: 1715085414149,
      recipeName: "Scotcheroos",
      versionNumber: "12",
      prepTime: "60",
      recipeDescription:
        "risen bread designed specifically to hold a hamburger patty and the accompanying topping",
      status: "Not Published",
      servingSize: "34",
      totalCookTime: "60",
      ingredients: [
        {
          type: "header",
          name: "Dry Ingredients",
        },
        {
          type: "ingredient",
          name: "Rice Krispies Cereal",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "Granulated Sugar",
          amount: 1,
          unit: "g",
        },
        {
          type: "header",
          name: "Wet Ingredients",
        },
        {
          type: "ingredient",
          name: "Light corn syrup",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "Creamy peanut butter",
          amount: 1,
          unit: "g",
        },
      ],

      directions: [
        {
          type: "header",
          name: "Prepare the Dough",
        },
        {
          type: "direction",
          name: "Preheat the oven to 350°F (175°C).",
        },
        {
          type: "header",
          name: "Bake the Cookies",
        },
        {
          type: "direction",
          name: "Scoop dough onto baking sheets.",
        },
      ],
      personalNote:
        "This recipe is a family favorite and always a hit at gatherings. Feel free to customize the ingredients to your taste preferences. Enjoy making and sharing these delicious cookies!",
      nutritionalFacts: {
        calories: 140.67,
        carbohydrates: 0,
        protein: 2.4833,
        cholesterol: 0,
        sodium: 0.06027,
        potassium: 0.00182,
        fiber: 22.63,
        sugar: 20.417,
        vitaminA: 0.0000137,
        calcium: 0.0072667,
        iron: 0.000567,
      },
      recipeTesting: {
        emails: ["acharkhani@intuceo.com", "legendry@gmail.com"],
        startDate: "13-06-2024",
        endDate: "13-07-2024",
      },
    },
  ],
  [
    {
      stage: "Serving",
      id: 1715085414150,
      recipeName: "Lemon Bars",
      versionNumber: "12",
      prepTime: "60",
      recipeDescription:
        "A delicious and keto-friendly meal to start off the day.",
      status: "Published",
      servingSize: "34",
      totalCookTime: "60",
      ingredients: [
        {
          type: "header",
          name: "Dry Ingredients",
        },
        {
          type: "ingredient",
          name: "butter",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "powdered sugar",
          amount: 1,
          unit: "g",
        },
        {
          type: "header",
          name: "Wet Ingredients",
        },
        {
          type: "ingredient",
          name: "all purpose flour",
          amount: 1,
          unit: "g",
        },
        {
          type: "ingredient",
          name: "salt",
          amount: 1,
          unit: "g",
        },
      ],
      directions: [
        {
          type: "header",
          name: "Step1",
        },
        {
          type: "direction",
          name: "Make burger seasoning by combining all spices in a bowl. Set aside.",
        },
      ],
      personalNote:
        "This recipe is a family favorite and always a hit at gatherings. Feel free to customize the ingredients to your taste preferences. Enjoy making and sharing these delicious cookies!",
      nutritionalFacts: {
        calories: 275,
        carbohydrates: 0,
        protein: 1.6,
        cholesterol: 0.0542,
        sodium: 0.04655 ,
        potassium: 0.0213,
        fiber: 0.4,
        sugar: 10.8,
        vitaminA: 0.001726,
        calcium: 0,
        iron: 0.00815,
      },
    },
  ],
];

export default dashBoardData;
