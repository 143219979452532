import React, { useState, useEffect } from "react";
import axios from "axios";
import { allReceipeDomainFormater } from "./ReceipeFormator/ReceipeFormator";
import { FoodNetworkFormator } from "./ReceipeFormator/FoodNetworkFormator";
import { FoodFormator } from "./ReceipeFormator/FoodFormator";
import "./TestingComponent.css";

const TestingComponent = ({ url }) => {
  const [scrapedData, setScrapedData] = useState(null);
  const [rawData, setRawData] = useState("");
  const API_KEY = "1d5b737262ef5dcb4d9900aba2ccda23";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.scraperapi.com/?api_key=${API_KEY}&url=${encodeURIComponent(
            url
          )}`
        );
        console.log("response data", response.data);
        setRawData(response.data);
        console.log("formated", FoodFormator(response, url));

        return;
        let receipeFormated = [];

        if (url.includes("www.foodnetwork.com"))
          receipeFormated = FoodNetworkFormator(response, url);
        else if (url.includes("www.allrecipes.com"))
          receipeFormated = allReceipeDomainFormater(response, url);
        else return;
      } catch (error) {
        console.error("Error fetching scraped data:", error);
      }
    };

    fetchData();
  }, [url, API_KEY]);

  return (
    <div>
      {rawData ? (
        <pre style={{ marginTop: "200px" }}> {rawData} </pre>
      ) : (
        <p> "Loading RawData"</p>
      )}
    </div>
  );
};

export default TestingComponent;

