import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import brandlogo from "../../assets/lecuckoologo1.png";
import createaccountimage from "../../assets/final_signup_bg.png";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import "./CreateAccount.css";
import { CiLinkedin } from "react-icons/ci";
import { AiOutlineFacebook, AiOutlineTiktok } from "react-icons/ai";
import { ReactComponent as TiktokIcon } from "../../assets/tiktok.svg";
import { toast } from "react-toastify";
import { NavBar } from "../../Components/navbar/NavBar";
import Footer from "../../Components/footer/Footer";

export const CreateAccount = () => {
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    createpassword: "",
    confirmpassword: "",
  });

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email.includes("@") || !formData.email.includes(".com")) {
      setError("Invalid email format.");
      return;
    }

    if (formData.createpassword !== formData.confirmpassword) {
      setError("Passwords do not match");
      return;
    }

    localStorage.setItem("email", formData.email);
    localStorage.setItem("password", formData.createpassword);

    toast.success("Successfully created account", {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      bodyClassName: "single__line__toast",
    });

    navigate("/");
    window.location.reload(); // Reload the page
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      if (!value.includes("@") || !value.includes(".com")) {
        setError("Invalid email format.");
      } else {
        setError("");
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <NavBar />
      <div className="signup__container">
        <div className="img__container">
          <img src={createaccountimage} alt="img" />
        </div>
        <div className="signup__form">
          {/* <div className="signup__top__section"> */}
          <img className="bottom__logo" src={brandlogo} alt="img" />
          {/* </div> */}
          <div className="medium sign__titletext">JOIN OUR COMMUNITY</div>
          <div className="social__media__section">
            <div className="medium">You may also sign up with</div>
            <div className="icons">
              <FaInstagram className="react__icons" />
              <TiktokIcon className="react__icons" />
              {/* <AiOutlineTiktok className="react__icons" /> */}
              <AiOutlineFacebook className="react__icons" />
              <FaGoogle className="react__icons" />
              {/* <CiLinkedin className="react__icons" /> */}
            </div>
          </div>
          <form className="create__account__form" onSubmit={handleSubmit}>
            <div className="form__group">
              <input
                // placeholder="First Name"
                type="text"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
              <div className="signup__label" htmlFor="firstname">
                First Name
              </div>
            </div>
            <div className="form__group">
              <input
                // placeholder="Last Name"
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                required
              />
              <div className="signup__label" htmlFor="firstname">
                Last Name
              </div>
            </div>
            <div className="form__group">
              <input
                // placeholder="Email Address"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <div className="signup__label" htmlFor="firstname">
                Email Address
              </div>
              {error && error.includes("Invalid email") && (
                <div className="error__msg">{error}</div>
              )}
            </div>
            <div className="form__group">
              <input
                // placeholder="Password"
                type="password"
                id="createpassword"
                name="createpassword"
                value={formData.createpassword}
                onChange={handleChange}
                required
              />
              <div className="signup__label" htmlFor="firstname">
                Password
              </div>
            </div>
            <div className="form__group">
              <input
                // placeholder="Confirm your password"
                type="password"
                id="confirmpassword"
                name="confirmpassword"
                value={formData.confirmpassword}
                onChange={handleChange}
                required
              />
              <div className="signup__label" htmlFor="firstname">
                Confirm your password
              </div>
              {error && error.includes("Passwords do not match") && (
                <div className="error__msg">{error}</div>
              )}
            </div>
            <div className="user__aggrement">
              <div className="user__aggrement__group">
                <input
                  type="checkbox"
                  id="agreeTerms"
                  name="agreeTerms"
                  required
                />
                <label htmlFor="agreeTerms">
                  I agree with the  <Link to="/still-cooking" className="terms-link" >Terms of Service</Link> and <Link to="/still-cooking" className="terms-link">Privacy Policy</Link>.
                </label>
              </div>
              <div className="user__aggrement__group">
                <input
                  type="checkbox"
                  id="emailNotification"
                  name="emailNotification"
                  // required
                />
                <label htmlFor="emailNotification">
                  I agree to receive email notifications from LeCuckoo.
                </label>
              </div>
            </div>
            <div>
              <button type="submit" className="signup__btn">
                Create Your Account
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
