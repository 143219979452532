import React, { useEffect, useState } from "react";
import { PageNavigations } from "./pagenaviation/PageNavigations";
import "./NavBar.css";
import { Link, useLocation } from "react-router-dom";

export const NavBar = () => {
  const [navbarStyle, setNavbarStyle] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setNavbarStyle({ backgroundColor: "rgb(2 32 51 / 31%)" });
      } else {
        setNavbarStyle({});
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();

  const home = location.pathname === "/";

  return (
    <>
      <nav style={navbarStyle} className="header__navbar">
        <div className="company__logo">
          {home ? (
            <Link to="/register" className="comunity__join">
              Join the community 
            </Link>
          ) : (
            <Link to="/" className="logo__text">
              LeCuckoo <span className="logo_name_additional"><sup>BETA</sup></span>
            </Link>
          )}
        </div>
        <div className="navbar__routers">
          <PageNavigations />
        </div>
      </nav>
    </>
  );
};
