import React from 'react';
import { Link } from "react-router-dom";
import "./Footer.css";


const Footer = () => {
  return (
    <footer className="footer">
        <div className="nav__links">
          <Link to="/still-cooking" className="redirect__to">About</Link>
          <Link to="/still-cooking" className="redirect__to" >Customer Support</Link>
          <Link to="/still-cooking" className="redirect__to">Become a Paid Tester</Link>
          <Link to="/still-cooking" className="redirect__to">Join the Influencer Team</Link>
        </div>
    </footer>
  );
};

export default Footer;
