import React , {useState, useEffect} from "react";
import { MdDelete } from "react-icons/md";
import { IoIosAdd, IoMdMenu } from "react-icons/io";
import "./Directions.css";

export const Directions = ({
  directions,
  setDirections,
  directionText,
  setDirectionText,
  handleDragStart,
  handleDrop,
  clearItemFields,
  deleteRecipes,
}) => {

  const [focusedInputIndex, setFocusedInputIndex] = useState(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);  
    };
  }, []);


  const addDirection = (type, isErrorVisible) => {
    if (directionText.trim() !== "") {
      const newItem = { type, name: directionText };

      setDirections([...directions, newItem]);
      clearItemFields();
    } else {
      if(isErrorVisible) alert("Invalid input");
    }
  };

  
  const handleClickOutside = (event) => {
    const type1Elements = document.querySelectorAll('.direction__container');
    const type2Section = document.querySelector('.directions__section');

    // console.log("click called");
    
    let clickedInsideType1 = false;
    
    type1Elements.forEach((type1) => {
      if (type1.contains(event.target)) {
        // console.log("table contents heder ");
        clickedInsideType1 = true;
      }
    });

    if (!clickedInsideType1 && (type2Section == null || !type2Section.contains(event.target))) {
      setFocusedInputIndex(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addDirection("direction", false);
    }
  };

  const handleFocus = (index) => {
    console.log("new focus called", index, focusedInputIndex);
    setFocusedInputIndex(index);
  };

  const handleInputChange = (e, index, field) => {
    const updatedDirections = directions.map((item, i) =>
      i === index ? { ...item, [field]: e.target.value } : item
    );
 
    setDirections(updatedDirections);
  };

  return (
    <>
      <div className="adding__items__section">
        <div className="title">Directions</div>

        {directions?.map((item, index) => (
          <div key={index} className="direction__container">
            {item.type === "header" && (
              <div
                key={index}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e, index, directions, setDirections)}
                className={`table__contents__header      
                    ${focusedInputIndex === index ? "focus_input" : ""}
                  `}
                onClick={ () => {handleFocus(index);}}
              >
                <div className="action__icons">
                  <IoMdMenu className="icons" />
                </div>

                <div className="editor__header__text">

                  <input
                      type="text"
                      value={item.name}
                      onChange={(e) => handleInputChange(e, index, 'name')}
                      onFocus={() => handleFocus(index)}
                      className="input__editable highlighter"                 
                    />

                </div>

                <div
                  onClick={() =>
                    deleteRecipes(directions, setDirections, index)
                  }
                  className="action__icons"
                >
                 <MdDelete className="icons" />
                </div>
              </div>
            )}
            {item.type === "direction" && (
              <div
                key={index}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e, index, directions, setDirections)}
                className={`table__contents directions 
                  ${focusedInputIndex === index ? "focus_input" : ""}
                `}
                onClick={ () => {handleFocus(index);}}
              >
                <div className="action__icons">
                  <IoMdMenu className="icons" />
                </div>
                
                <div className="directions__info">
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) => handleInputChange(e, index, 'name')}
                    onFocus={() => handleFocus(index)}
                    className="input__editable"
                  />
                </div>


                <div
                  onClick={() =>
                    deleteRecipes(directions, setDirections, index)
                  }
                  className="action__icons"
                >
              <MdDelete className="icons" />
                </div>
              </div>
            )}
          </div>
        ))}

        <div 
          className={`directions__section 
                      ${focusedInputIndex === -1 ? "focus_input" : ""}
                    `}
          onClick={ () => {handleFocus(-1);}}
          >
          <div className="draggable__icon">
            <IoMdMenu className="icons" />
          </div>
          <div className="direction__input">
            <input
              type="text"
              placeholder="Enter directions"
              className="direction__input__box"
              value={directionText}
              required
              onChange={(e) => setDirectionText(e.target.value)}
              onKeyPress={handleKeyPress}
              onFocus={() => handleFocus(-1)}
              onBlur={() => {addDirection("direction", false)}}
            />
          </div>
          <MdDelete onClick={clearItemFields} className="icons" />
        </div>
        <div className="btn__sections">
          <div className="cursor__texts_blue bold" onClick={() => addDirection("direction", true)}>
            <IoIosAdd /> Add Directions
          </div>
          <div className="cursor__texts_blue bold" onClick={() => addDirection("header", true)}>
            <IoIosAdd /> Add Header
          </div>
        </div>
      </div>
    </>
  );
};
