import React, { useState, useEffect } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import "./RecipeTable.css";
import { IoCloseOutline } from "react-icons/io5";

export const RecipeTable = ({ dashBoardData, filterStage }) => {
  const [recipeItems, setRecipeItems] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const storedRecipes = sessionStorage.getItem("recipes");
    if (storedRecipes) {
      const parsedRecipes = JSON.parse(storedRecipes);
      setRecipeItems(removeDuplicates(parsedRecipes));
    } else {
      const localRecipeItems = dashBoardData?.flatMap((item) => item) || [];
      const uniqueLocalItems = removeDuplicates(localRecipeItems);
      sessionStorage.setItem("recipes", JSON.stringify(uniqueLocalItems));
      setRecipeItems(uniqueLocalItems);
    }
  }, [dashBoardData]);

  const deleteRecipe = (id) => {
    const updatedItems = recipeItems.filter((item) => item.id !== id);
    setRecipeItems(updatedItems);
    sessionStorage.setItem("recipes", JSON.stringify(updatedItems));
  };

  const handleConfirmDelete = () => {
    deleteRecipe(deleteId);
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const removeDuplicates = (items) => {
    const seen = new Set();
    return items.filter((item) => {
      if (seen.has(item.id)) {
        return false;
      } else {
        seen.add(item.id);
        return true;
      }
    });
  };

  console.log("recipeItems", recipeItems);
  return (
    <div className="table__container">
      {recipeItems.length > 0 ? (
        <div className="table">
          <div className="table__row table__header">
            <div className="table__cell recipe__name">Recipe Name</div>
            <div className="table__cell description_name">Description</div>
            <div className="table__cell status">Status</div>
            <div className="table__cell action">Edit</div>
            <div className="table__cell action">Delete</div>
          </div>
          {recipeItems
            .filter((item) => item.stage === filterStage)
            .map((item, index) => (
              <div className="table__row" key={index}>
                <div className="table__cell recipe__name">
                  {item.stage === "Serving" ? (
                    <div className="view__recipe">
                      {item.recipeName}{" "}
                      <Link
                        to={`/dashboard/view-recipe/${item.id}`}
                        className="cursor__texts_pink"
                      >
                        View
                      </Link>
                    </div>
                  ) : (
                    <div>{item.recipeName}</div>
                  )}
                </div>
                <div className="table__cell description_name">
                  {item.recipeDescription}
                </div>
                <div className="table__cell status">{item.status}</div>
                <div className="table__cell action">
                  <Link
                    style={{ color: "black" }}
                    to={`/recipe-editor?id=${item.id}`}
                  >
                    <MdEdit className="icons" />
                  </Link>
                </div>
                <div className="table__cell action">
                  <MdDelete
                    className="icons"
                    onClick={() => {
                      setDeleteId(item.id);
                      setShowConfirmation(true);
                    }}
                  />
                </div>
              </div>
            ))}
          {showConfirmation && (
            <>
              <div className="popup__overlay"></div>
              <div className="confirmation__box show">
                <div className="close__btn" onClick={handleCancelDelete}>
                  <IoCloseOutline className="icons" />
                </div>
                <div className="text">
                  Are you sure , Do you want to delete ?
                </div>
                <div className="confirmation-buttons">
                  <div
                    className="cursor__texts_pink"
                    onClick={handleConfirmDelete}
                  >
                    Confirm
                  </div>
                  <div
                    className="cursor__texts_pink"
                    onClick={handleCancelDelete}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="empty__table">
          <div className="msg">
            You haven’t created a recipe yet.{" "}
            <Link to="/recipe-editor" className="create__link">
              Create one!
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
