import React from "react";
import "./Nutritionals.css";

export const Nutritionals = ({ nutritionalValues }) => {
  return (
    <>
      <div className="nutritonal__section scrollbar">
        <h4>Nutritional Info (Per Serving)</h4>
        <div className="nutritional__values__container">
          {Object.entries(nutritionalValues).map(([key, value]) => (
            <div key={key} className="value">
              <div>{key}</div>
              <div>{value}g</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
