import React from "react";
import "./Simple.css";

export const SimpleDemo = () => {
  let demoUrl =
    "https://lecuckoo.blob.core.windows.net/lecuckoo/Demo.mp4";

  return (
    <div className="simpleDemo">
      <video
      className="simple__bg"
      controls = "true"
      autoPlay
      loop
      playsInline
      preload="metadata"
      >
        <source src={demoUrl} type="video/mp4"></source>
      </video>
    </div>
  );
};
