import React, { useState } from "react";
import { NavBar } from "../navbar/NavBar";
import Footer from "../footer/Footer";
import "./ViewFeedback.css";
import { IoReturnUpBack } from "react-icons/io5";
import { MdOutlineSend } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

export const ViewFeedback = () => {
  const [openPopup, setOpenPopup] = useState(false);

  
  const navigate = useNavigate();

  const redirectToRecipeEdit = () => {
    navigate(-1); // This navigates back to the previous page
  };

  return (
    <>
      <NavBar />
      <div className="feedback__section">
        <div className="redirect__back___btns">
          <div onClick={redirectToRecipeEdit} className="medium go__back">
            <IoReturnUpBack /> Back to Editor
          </div>
          {/* <div className="medium testing__done ">
            <MdOutlineDone /> Done
          </div> */}
        </div>

        <div className="feedback__container">
          <div className="xtra__large">View Feedback</div>
          <div className="small">
            Read your recipe’s feedback from your testers below.
          </div>
          <div className="medium">Recipe testing timeframe:</div>
          <div className="testing__timeframe medium">
            <div className="timeFrame">4/22/2024</div>
            <span>-</span>
            <div className="timeFrame">5/13/2024</div>
          </div>
        </div>

        {openPopup && (
          <>
            <div className="pop__upMessage">
              {/* <div className="medium name__title">Symone feedback for &lt;Recipe name&gt;</div> */}
              <div className="medium name__title">Symone feedback for 1/4 lb burger</div>
              <div className="small">
                Your quarter pounder burger recipe is delightful and showcases
                culinary expertise. The juicy, well-seasoned patty brings a
                satisfying, meaty flavor. Fresh toppings like crisp lettuce,
                ripe tomatoes, and onions offer a nice contrast in textures and
                tastes. The choice of cheese adds creamy richness that enhances
                the overall experience. The bun is soft yet sturdy, holding all
                the ingredients together for each flavorful bite. To elevate
                your burger, consider adding a signature sauce for extra
                complexity and depth. A tangy, smoky, or spicy sauce could make
                your recipe stand out even more. Experimenting with different
                pickles or condiments might also provide a unique twist.
                Overall, your quarter pounder burger is a tasty combination of
                flavors and textures that leaves a lasting impression. Keep up
                the excellent work!
              </div>

              <div className="popip__btns medium">
                <div className="cursor__texts_blue" onClick={() => setOpenPopup(false)}>Close</div>
                <Link to="/still-cooking" className="cursor__texts_pink">Reply</Link>
              </div>
            </div>
          </>
        )}

        <div className="feedback__details">
        {/* <div className="small">Feedback for &lt;Recipe name&gt;</div> */}
        <div className="small">Feedback for 1/4 lb burger</div>

          {/* <div className="feedBack__table">
            <div className="tbl__header">
              <div className="small">Tester Name</div>
              <div className="small">Feedback</div>
              <div className="small">Feedback</div>
            </div>
            <div className="user__section">
              <div className="testers__name">
                <input className="small" type="text" placeholder="Enter name" />
              </div>
              <div className="feedback__msg">
                <input className="small" type="text" placeholder="Feedback" />
              </div>
              <div className="msg__icon">
              <MdOutlineSend className="icons" />
              </div>
            </div>
          </div> */}
          <div class="tbl__headers small">
            <div class="tbl__column one">Tester Name</div>
            <div class=" tbl__column two">Feedback</div>
            <div class="tbl__column three">Message</div>
          </div>
          <div class="user__feedbacks small">
            <div class="users__feedback one">Symone</div>
            <div class="users__feedback two">
              Your quarter pounder burger recipe is delightful and showcases...
              {"      "}
              <span onClick={() => setOpenPopup(!openPopup)} className="medium">
                Expand
              </span>
            </div>
            <div class="users__feedback three">
              {" "}
              <MdOutlineSend className="icons" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
