function convertToMinutes(duration){
    // Regular expression to parse the ISO 8601 duration string
  const regex = /PT(\d+H)?(\d+M)?/;
  const matches = duration.match(regex);

  // Extract hours and minutes from the matched groups
  const hours = matches[1] ? parseInt(matches[1], 10) : 0;
  const minutes = matches[2] ? parseInt(matches[2], 10) : 0;

  // Convert the total duration to minutes
  return (hours * 60) + minutes;
}
  




export  function FoodFormator(response, url){

    let formatedReceipe = {};

    const parsedData = new DOMParser().parseFromString(response.data, 'text/html');
    const scriptTag = parsedData.querySelector('script[type="application/ld+json"]');
    console.log("scriptTag",scriptTag);
    console.log("scriptJSON",JSON.parse(scriptTag.textContent));
    let receipeDetails = JSON.parse(scriptTag.textContent);

    formatedReceipe["recipeName"] = receipeDetails["name"];
    formatedReceipe["stage"] = "InProgress";
    formatedReceipe["prepTime"] = convertToMinutes(receipeDetails["prepTime"]);
    formatedReceipe["totalCookTime"] = convertToMinutes(receipeDetails["totalTime"]);
    formatedReceipe["status"] = "Not Published";
    formatedReceipe["personalNote"] = "Enter Note";
    formatedReceipe["id"] = Date.now();
    formatedReceipe["recipeDescription"] = receipeDetails["description"];
    formatedReceipe["url"]=url;              //  receipeDetails["url"];

    formatedReceipe["ingredients"] = [    
        {
            type: "header",
            name: "Ingredients",
        }
    ];

    receipeDetails["recipeIngredient"].forEach(ingrdient => {
        let formatedIngredient = {
            type: "ingredient",
            name: ingrdient,
            amount: 0,
            unit: ""
        };
        formatedReceipe["ingredients"].push(formatedIngredient); 
    });


    formatedReceipe["directions"] = [];
    receipeDetails["recipeInstructions"].forEach(instruction => {
        let formatedInstruction = {
            type: "direction",
            name: instruction.text
        };
        formatedReceipe["directions"].push(formatedInstruction); 
    });

    let nutrition = receipeDetails["nutrition"]

    formatedReceipe["nutritionalFacts"] = {
        calories: nutrition["calories"],
        carbohydrates: nutrition["carbohydrateContent"],
        protein: nutrition["proteinContent"],
        cholesterol: nutrition["cholesterolContent"],
        sodium: nutrition["sodiumContent"],
        potassium: "",
        fiber: nutrition["fiberContent"],
        sugar: nutrition["sugarContent"],
        vitaminA: 0,
        calcium: 0,
        iron: 0
    }


    
    return formatedReceipe;

  

    // console.log("ingredients",receipeDetails["recipeIngredient"]);

   

    // receipeDetails["recipeIngredient"].forEach(ingrdient => {
    //     let formatedIngredient = {
    //         type: "ingredient",
    //         name: ingrdient,
    //         amount: 0,
    //         unit: ""
    //     };
    //     formatedReceipe["ingredients"].push(formatedIngredient); 
    // });

    // formatedReceipe["directions"] = [];
    // receipeDetails["recipeInstructions"].forEach(instruction => {
    //     let formatedInstruction = {
    //         type: "direction",
    //         name: instruction.text
    //     };
    //     formatedReceipe["directions"].push(formatedInstruction); 
    // });

    // let nutrition = receipeDetails["nutrition"]

    // formatedReceipe["nutritionalFacts"] = {
    //     calories: nutrition["calories"],
    //     carbohydrates: nutrition["carbohydrateContent"],
    //     protein: nutrition["proteinContent"],
    //     cholesterol: nutrition["cholesterolContent"],
    //     sodium: nutrition["sodiumContent"],
    //     potassium: "",
    //     fiber: nutrition["fiberContent"],
    //     sugar: nutrition["sugarContent"],
    //     vitaminA: "",
    //     calcium: "",
    //     iron: ""
    // }

    // formatedReceipe["nutritionalFacts"] = convertValues(formatedReceipe["nutritionalFacts"]);



    // {
    //     "@type": "NutritionInformation",
    //     "servingSize": "1 of 10 servings",
    //     "calories": "411",
    //     "fatContent": "29g",
    //     "saturatedFatContent": "10g",
    //     "carbohydrateContent": "24g",
    //     "fiberContent": "8g",
    //     "sugarContent": "11g",
    //     "proteinContent": "20g",
    //     "cholesterolContent": "47mg",
    //     "sodiumContent": "711mg"
    // }

    // nutritionalFacts: {
    //     calories: 210,
    //     carbohydrates: 45,
    //     protein: 170,
    //     cholesterol: 123,
    //     sodium: 90,
    //     potassium: 52,
    //     fiber: 45,
    //     sugar: 30,
    //     vitaminA: 48,
    //     calcium: 60,
    //     iron: 34,
    //   },

    console.log("fomated Receipe",formatedReceipe);

    return formatedReceipe;
}