import React, { useEffect, useState } from "react";
import "./GptReview.css";

export const GptReview = ({ respoGpt, gptResponseLines, loading, setLoading }) => {
  const [showReview, setShowReview] = useState(false);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);

  const reviewButtonText = showReview ? "Accept Review" : "Begin Review";

  const resetReview = () => {
    setShowReview(false);
    setCurrentLineIndex(0);
  };

  const handleToggleReview = async () => {
    if (showReview) {
      resetReview();
    } else {
      await respoGpt();
      setShowReview(true);
      setCurrentLineIndex(0);
    }
  };

  useEffect(() => {
    if (showReview && gptResponseLines.length > 0) {
      const timer = setInterval(() => {
        if (currentLineIndex < gptResponseLines.length) {
          setCurrentLineIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [showReview, gptResponseLines, currentLineIndex]);

  return (
    <div className="gpt__section">
      {!showReview ? (
        <div className="gpt__review">
          <div className="title">Luc AI Review</div>
          <div className="para">
            Get AI-powered suggestions to improve your recipe
          </div>
        </div>
      ) : null}

      {loading ? (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : showReview ? (
        <div className="review__message scrollbar">
          <div className="review__content">
            {gptResponseLines.length > 0
              ? gptResponseLines.slice(0, currentLineIndex).map((line, index) =>
                  line ? (
                    <div className="review-response smaller" key={index}>
                      {line}
                    </div>
                  ) : null
                )
              : null}
          </div>
        </div>
      ) : null}

      <div className="review__btn" onClick={handleToggleReview}>
        {reviewButtonText}
      </div>
    </div>
  );
};
