import React, { useState, useEffect } from "react";
import A1 from "../../../assets/A1.png";
import A2 from "../../../assets/A2.png";
import A3 from "../../../assets/A3.png";
import A4 from "../../../assets/A4.png";
import A5 from "../../../assets/A5.png";
import A6 from "../../../assets/A6.png";
import A7 from "../../../assets/A7.png";
import A8 from "../../../assets/A8.png";
import A9 from "../../../assets/A9.png";
import A10 from "../../../assets/A10.png";
import A11 from "../../../assets/A11.png";
import A12 from "../../../assets/A12.png";
import A13 from "../../../assets/A13.png";
import A14 from "../../../assets/A14.png";

import "./Recipes.css";

export const Recipes = () => {
  const allRecipeImages = [
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    A11,
    A12,
    A13,
    A14
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentSlide((prevSlide) => (prevSlide + 1) % allRecipeImages.length);
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, [allRecipeImages.length]);

  // const showRecipes = allRecipeImages
  //   .slice(currentSlide, currentSlide + 3)
  //   .concat(
  //     allRecipeImages.slice(
  //       0,
  //       Math.max(0, 3 - allRecipeImages.length + currentSlide)
  //     )
  //   );

  return (
    <div className="recipes">
      <div className="slider">
        <div className="slide-track">
          {allRecipeImages.map((recipe, index) => (
            <div key={index} className="slide">
              <img src={recipe} loading="lazy" alt={`Recipe ${index + 1}`}  />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
