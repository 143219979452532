import React, { createContext } from "react";
// import { userLogOut, userSignIn, userSignUp } from "../api/localStorage";

export const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
  // const [user, setUser] = useState(false);

  // const [error, setError] = useState(null);

  // const signIn = ({ email, password }) => {
  //   const isLoggedIn = userSignIn({ email, password });
  //   if (isLoggedIn) {
  //     setUser({ email });
  //     return true;
  //   } else {
  //     setError("Email or password is invalid");
  //     return false;
  //   }
  // };

  // const signUp = ({ email, password }) => {
  //   const isSignedUp = userSignUp({ email, password });
  //   if (isSignedUp) {
  //     return true;
  //   } else {
  //     setError("Allready signed up");
  //     return false;
  //   }
  // };

  // const logOut = () => {
  //   userLogOut();
  //   setUser(null);
  // };

  return (
    // <AppContext.Provider value={{ user, error, signIn, signUp, logOut }}>
    //   {children}
    // </AppContext.Provider>
    <AppContext.Provider>
      {children}
    </AppContext.Provider>
  );
};
