import React from "react";
import brandlogo from "./assets/lecuckoologo.png";
import  "./Loader.css"

export const Loader = () => {
  return (
    <div className="loader__component">
<span class="loader"></span>
    </div>
  );
};
