import React, { useEffect } from 'react';

const EmbedVismeForm = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://static-bundles.visme.co/forms/vismeforms-embed.js';
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);
    
    // Cleanup script when component unmounts
    return () => {
    //   document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="visme_d"
      data-title="Untitled Project"
      data-url="epq6jr31-untitled-project?fullPage=true"
      data-domain="forms"
      data-full-page="true"
      data-min-height="100vh"
      data-form-id="80550"
    ></div>
  );
};

export default EmbedVismeForm;
