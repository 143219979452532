const loadIngredients = {
  "Worchester Sauce": {
    amount: 1,
    unit: "tsp",
    calories: 1,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.023,
    carbs: 0.1,
    fiber: 0,
    sugar: 0,
    protein: 0.1,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0001,
    iron: 0,
    potassium: 0.00253,
  },
  "Sliced Beefsteak Tomatoes": {
    amount: 1,
    unit: "items",
    calories: 4,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.001,
    carbs: 0.8,
    fiber: 0.2,
    sugar: 0.5,
    protein: 0.2,
    vitaminA: 8.6e-6,
    VitaminC: 0.0028,
    calcium: 0.0021,
    iron: 0.0001,
    potassium: 0.0486,
  },
  "Ketchup": {
    amount: 1,
    unit: "tbsp",
    calories: 3,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0267,
    carbs: 0.8,
    fiber: 0,
    sugar: 0.7,
    protein: 0,
    vitaminA: 5e-6,
    VitaminC: 0.0002,
    calcium: 0,
    iron: 0,
    potassium: 0,
  },
  "Mayonnaise": {
    amount: 1,
    unit: "tbsp",
    calories: 16,
    TotalFat: 1.7,
    SaturatedFat: 0.3,
    TransFat: 0,
    cholesterol: 0.001,
    sodium: 0.0146,
    carbs: 0,
    fiber: 0,
    sugar: 0,
    protein: 0.1,
    vitaminA: 4e-7,
    VitaminC: 0,
    calcium: 0.0002,
    iron: 0,
    potassium: 0.0005,
  },
  "Salt": {
    amount: 1,
    unit: "tsp",
    calories: 0,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.5814,
    carbs: 0,
    fiber: 0,
    sugar: 0,
    protein: 0.1,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0004,
    iron: 0,
    potassium: 0.0001,
  },
  "Freshly ground black pepper": {
    amount: 1,
    unit: "tsp",
    calories: 1,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0001,
    carbs: 0.4,
    fiber: 0.1,
    sugar: 0,
    protein: 0.1,
    vitaminA: 2e-7,
    VitaminC: 0,
    calcium: 0.0025,
    iron: 0.0001,
    potassium: 0.0076,
  },
  "Garlic Powder": {
    amount: 1,
    unit: "tsp",
    calories: 3,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0005,
    carbs: 0.6,
    fiber: 0.1,
    sugar: 0,
    protein: 0.1,
    vitaminA: 0,
    VitaminC: 0.0004,
    calcium: 0.0006,
    iron: 0,
    potassium: 0.0092,
  },
  "Rice Vinegar": {
    amount: 1,
    unit: "tbsp",
    calories: 0,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0,
    carbs: 0,
    fiber: 0,
    sugar: 0,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0,
    iron: 0,
    potassium: 0,
  },
  "Sesame Oil": {
    amount: 1,
    unit: "tsp",
    calories: 10,
    TotalFat: 1.1,
    SaturatedFat: 0.2,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0,
    carbs: 0,
    fiber: 0,
    sugar: 0,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0,
    iron: 0,
    potassium: 0,
  },
  "Ground Ginger": {
    amount: 1,
    unit: "tsp",
    calories: 2,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0001,
    carbs: 0.3,
    fiber: 0.1,
    sugar: 0,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0005,
    iron: 0.0001,
    potassium: 0.0059,
  },
  "Granulated Sugar": {
    amount: 1,
    unit: "cups",
    calories: 32,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0001,
    carbs: 8.3,
    fiber: 0,
    sugar: 8.3,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0001,
    iron: 0,
    potassium: 0.0002,
  },
  "Light Corn Syrup": {
    amount: 1,
    unit: "cups",
    calories: 40,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0088,
    carbs: 10.9,
    fiber: 0,
    sugar: 10.9,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0018,
    iron: 0,
    potassium: 0.0001,
  },
  "Creamy Peanut Butter": {
    amount: 1,
    unit: "cups",
    calories: 64,
    TotalFat: 5.5,
    SaturatedFat: 1.1,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.045,
    carbs: 2.4,
    fiber: 0.5,
    sugar: 1.1,
    protein: 2.4,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0053,
    iron: 0.0002,
    potassium: 0.06,
  },
  "All Purpose Flour": {
    amount: 1,
    unit: "cups",
    calories: 51,
    TotalFat: 0.1,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0003,
    carbs: 10.6,
    fiber: 0.4,
    sugar: 0,
    protein: 1.4,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0021,
    iron: 0.0006,
    potassium: 0.0149,
  },
  "Large Eggs": {
    amount: 1,
    unit: "items",
    calories: 10.75,
    TotalFat: 0.525,
    SaturatedFat: 0.175,
    TransFat: 0,
    cholesterol: 0.020675,
    sodium: 0.0079,
    carbs: 0.05,
    fiber: 0,
    sugar: 0.025,
    protein: 0.7,
    vitaminA: 8.9e-6,
    VitaminC: 0,
    calcium: 0.0031,
    iron: 0.0001,
    potassium: 0.007675,
  },
  "Ground Chuck 80/20": {
    amount: 1,
    unit: "lb",
    calories: 191.33,
    TotalFat: 15.07,
    SaturatedFat: 5.73,
    TransFat: 0.87,
    cholesterol: 0.053,
    sodium: 0.05,
    carbs: 0,
    fiber: 0,
    sugar: 0,
    protein: 13,
    vitaminA: 3e-6,
    VitaminC: 0,
    calcium: 0.0136,
    iron: 0.00147,
    potassium: 0.2034,
  },
  "Onion Powder": {
    amount: 1,
    unit: "tsp",
    calories: 1.5,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0003,
    carbs: 0.3,
    fiber: 0.05,
    sugar: 0.05,
    protein: 0.05,
    vitaminA: 0,
    VitaminC: 0.0001,
    calcium: 0.00155,
    iron: 0,
    potassium: 0.00395,
  },
  "Mustard": {
    amount: 1,
    unit: "tsp",
    calories: 0.5,
    TotalFat: 0.05,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0092,
    carbs: 0.05,
    fiber: 0.05,
    sugar: 0,
    protein: 0.05,
    vitaminA: 5e-8,
    VitaminC: 0,
    calcium: 0.00055,
    iron: 0,
    potassium: 0.00125,
  },
  "Hamburger Buns": {
    amount: 1,
    unit: "items",
    calories: 23.67,
    TotalFat: 0.32,
    SaturatedFat: 0.008,
    TransFat: 0.002,
    cholesterol: 8e-5,
    sodium: 0.0324,
    carbs: 4.25,
    fiber: 0.15,
    sugar: 0.65,
    protein: 0,
    vitaminA: 0,
    VitaminC: 8e-5,
    calcium: 0.013,
    iron: 0.0002,
    potassium: 0,
  },
  "Sliced Cheddar Cheese": {
    amount: 1,
    unit: "items",
    calories: 18.83,
    TotalFat: 1.55,
    SaturatedFat: 0.88,
    TransFat: 0.05,
    cholesterol: 0.00462,
    sodium: 0.03047,
    carbs: 0.15,
    fiber: 0,
    sugar: 0.02,
    protein: 1.07,
    vitaminA: 1.54e-5,
    VitaminC: 0,
    calcium: 0.033,
    iron: 0,
    potassium: 0.00355,
  },
  "White Onion": {
    amount: 1,
    unit: "items",
    calories: 8,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0008,
    carbs: 1.8,
    fiber: 0.4,
    sugar: 0.8,
    protein: 0.2,
    vitaminA: 0,
    VitaminC: 0.0014,
    calcium: 0.0042,
    iron: 0,
    potassium: 0.0268,
  },
  "Lettuce Leaves": {
    amount: 1,
    unit: "items",
    calories: 0.167,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.000283,
    carbs: 0.033,
    fiber: 0.017,
    sugar: 0,
    protein: 0.017,
    vitaminA: 3.7e-6,
    VitaminC: 0.0001,
    calcium: 0.000367,
    iron: 1.7e-5,
    potassium: 0.00193,
  },
  "Pickle Slices": {
    amount: 1,
    unit: "items",
    calories: 0.1667,
    TotalFat: 0.0056,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0094,
    carbs: 0.0278,
    fiber: 0.0111,
    sugar: 0.0111,
    protein: 0.0056,
    vitaminA: 0,
    VitaminC: 3e-5,
    calcium: 0.0006,
    iron: 5e-5,
    potassium: 0.0014,
  },
  "Gochujang Chicken Wings": {
    carbs: 2.75,
    sugar: 1.17,
    vitaminA: 2.4e-5,
    calcium: 0.0481,
    iron: 0.0003,
    potassium: 0.05215,
  },
  "Baking Powder": {
    amount: 1,
    unit: "tbsp",
    calories: 3.5,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.00315,
    carbs: 1.65,
    fiber: 0.1,
    sugar: 0,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0.0002,
    calcium: 0.1516,
    iron: 0.0003,
    potassium: 0.3535,
  },
  "Gochujang Paste": {
    amount: 1,
    unit: "tbsp",
    calories: 5.67,
    TotalFat: 0.267,
    SaturatedFat: 0.0667,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.00275,
    carbs: 1,
    fiber: 0.7,
    sugar: 0.1667,
    protein: 0.2667,
    vitaminA: 0.0001058,
    VitaminC: 0.002233,
    calcium: 0.0003,
    iron: 0.0009,
    potassium: 0.047,
  },
  "Reduced Sodium Soy Sauce": {
    amount: 1,
    unit: "tbsp",
    calories: 3.5,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.1084,
    carbs: 0.55,
    fiber: 0,
    sugar: 0.2,
    protein: 0.3,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0004,
    iron: 0,
    potassium: 0.1162,
  },
  "Honey": {
    amount: 1,
    unit: "tbsp",
    calories: 16,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0002,
    carbs: 4.33,
    fiber: 0,
    sugar: 4.33,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0003,
    iron: 0,
    potassium: 0.0027,
  },
  "Sliced Green Onions": {
    amount: 1,
    unit: "tbsp",
    calories: 0.5,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.00025,
    carbs: 0.1,
    fiber: 0.05,
    sugar: 0.05,
    protein: 0,
    vitaminA: 3,
    VitaminC: 0.0002,
    calcium: 0.0008,
    iron: 0,
    potassium: 0,
  },
  "Sesame Seeds": {
    amount: 1,
    unit: "g",
    calories: 1.4,
    TotalFat: 0.123,
    SaturatedFat: 0.0176,
    TransFat: 0,
    cholesterol: 0,
    sodium: 2.64e-5,
    carbs: 0.0615,
    fiber: 0.0264,
    sugar: 0,
    protein: 0.044,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0.0024,
    iron: 3.52e-5,
    potassium: 0.0012,
  },
  "Scotcheroos": {
    calories: 7.5,
    sodium: 0.58515,
    fiber: 0.3,
    protein: 0.3,
    vitaminA: 2e-7,
    calcium: 0.1551,
    iron: 0.0004,
    potassium: 0.3704,
  },
  "Rice Krispies Cereal": {
    amount: 1,
    unit: "cups",
    calories: 4.67,
    TotalFat: 0.0167,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.00637,
    carbs: 1.03,
    fiber: 0,
    sugar: 0.117,
    protein: 0.0833,
    vitaminA: 1.37e-5,
    VitaminC: 0.000767,
    calcium: 6.67e-5,
    iron: 0.000367,
    potassium: 0.00152,
  },
  "Semi Sweet Chocolate Chips": {
    amount: 1,
    unit: "oz",
    calories: 5.92,
    TotalFat: 0.333,
    SaturatedFat: 0.208,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0,
    carbs: 0.758,
    fiber: 0.0833,
    sugar: 0.675,
    protein: 0.0833,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0,
    iron: 0,
    potassium: 0,
  },
  "Butterscotch Chips": {
    amount: 1,
    unit: "oz",
    calories: 4.58,
    TotalFat: 0.0417,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0.000108,
    sodium: 0.00461,
    carbs: 1.066,
    fiber: 0,
    sugar: 0.949,
    protein: 0,
    vitaminA: 3.33e-7,
    VitaminC: 0,
    calcium: 5e-5,
    iron: 0,
    potassium: 3.332e-5,
  },
  "Lemon Bars": {
    calories: 140.67,
    sodium: 0.06027,
    carbs: 22.63,
    sugar: 20.417,
    protein: 2.4833,
    vitaminA: 1.37e-5,
    calcium: 0.0072667,
    iron: 0.000567,
    potassium: 0.00182,
  },
  "Butter": {
    amount: 1,
    unit: "cups",
    calories: 180,
    TotalFat: 20.4,
    SaturatedFat: 12.8,
    TransFat: 0,
    cholesterol: 0.0542,
    sodium: 0.0028,
    carbs: 0,
    fiber: 0,
    sugar: 0,
    protein: 0.2,
    vitaminA: 0.0001726,
    VitaminC: 0,
    calcium: 0.006,
    iron: 0.006,
    potassium: 0.006,
  },
  "Powdered Sugar": {
    amount: 1,
    unit: "cups",
    calories: 44,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0004,
    carbs: 11.2,
    fiber: 0,
    sugar: 10.8,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0,
    calcium: 0,
    iron: 0,
    potassium: 0.0004,
  },
  "Large Lemons Zested": {
    amount: 1,
    unit: "items",
    calories: 2.5,
    TotalFat: 0.05,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.0002,
    carbs: 0.85,
    fiber: 0.25,
    sugar: 0.25,
    protein: 0.1,
    vitaminA: 1e-7,
    VitaminC: 0.00495,
    calcium: 0.00245,
    iron: 5e-5,
    potassium: 0.0129,
  },
  "Fresh Lemon Juice": {
    amount: 1,
    unit: "cups",
    calories: 6.06,
    TotalFat: 0,
    SaturatedFat: 0,
    TransFat: 0,
    cholesterol: 0,
    sodium: 0.000303,
    carbs: 1.818,
    fiber: 0,
    sugar: 0.606,
    protein: 0,
    vitaminA: 0,
    VitaminC: 0.010605,
    calcium: 0.001515,
    iron: 0,
    potassium: 0.027876,
  },

  "Cheese, Cheddar, White, Shredded, Chilled": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 110.0,
        "TotalFat": 9.0,
        "SaturatedFat": 5.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.18,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0000002,
        "calcium": 0.193,
        "iron": 0.0,
        "potassium": 0.02
    },
    "Cheese, Cheddar, Yellow, Shredded, Chilled": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 110.0,
        "TotalFat": 9.0,
        "SaturatedFat": 5.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.18,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0000002,
        "calcium": 0.193,
        "iron": 0.0,
        "potassium": 0.02
    },
    "Cheese, Cheddar, Yellow, Reduced Fat, Shredded, Chilled": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 80.0,
        "TotalFat": 6.0,
        "SaturatedFat": 3.5,
        "TransFat": 0.0,
        "cholesterol": 0.02,
        "sodium": 0.17,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 7.0,
        "vitaminD": 0.0000001,
        "calcium": 0.207,
        "iron": 0.0,
        "potassium": 0.015
    },
    "Cheese, American, Yellow, Pasteurized, Loaves, Chilled": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 100.0,
        "TotalFat": 9.0,
        "SaturatedFat": 5.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.23,
        "carbs": 2.0,
        "fiber": 0.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 5.0,
        "vitaminD": 0.0,
        "calcium": 0.167,
        "iron": 0.0,
        "potassium": 0.194
    },
    "Cheese, American, Yellow, Pasteurized, Sliced, Chilled": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 100.0,
        "TotalFat": 9.0,
        "SaturatedFat": 5.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.26,
        "carbs": 2.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 5.0,
        "vitaminD": 0.0,
        "calcium": 0.16,
        "iron": 0.0,
        "potassium": 0.16
    },
    "Cheese, Mozzarella, Low Moisture Part Skim, Shredded, Frozen": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 80.0,
        "TotalFat": 5.0,
        "SaturatedFat": 3.0,
        "TransFat": 0.0,
        "cholesterol": 0.015,
        "sodium": 0.16,
        "carbs": 2.0,
        "fiber": 1.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 7.0,
        "vitaminD": 0.0,
        "calcium": 0.19,
        "iron": 0.0,
        "potassium": 0.0
    },
    "Cheese, Mozzarella, Low Moisture Part Skim, Loaves, Frozen ": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 83.0,
        "TotalFat": 6.0,
        "SaturatedFat": 3.0,
        "TransFat": 0.0,
        "cholesterol": 0.016,
        "sodium": 0.168,
        "carbs": 2.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 7.0,
        "vitaminD": 0.0,
        "calcium": 0.195,
        "iron": 0.0,
        "potassium": 0.0
    },
    "Cheese, Blended American, Yellow, Reduced Fat, Sliced, Chilled": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 80.0,
        "TotalFat": 5.0,
        "SaturatedFat": 3.5,
        "TransFat": 0.0,
        "cholesterol": 0.015,
        "sodium": 0.29,
        "carbs": 2.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0,
        "calcium": 0.181,
        "iron": 0.0,
        "potassium": 0.092
    },
    "Cheese, Blended American, White, Reduced Fat, Sliced, Chilled": {
        "amount": 1.0,
        "unit": "ounce",
        "calories": 80.0,
        "TotalFat": 5.0,
        "SaturatedFat": 3.5,
        "TransFat": 0.0,
        "cholesterol": 0.015,
        "sodium": 0.29,
        "carbs": 2.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0,
        "calcium": 0.181,
        "iron": 0.0,
        "potassium": 0.092
    },
    "Eggs, Liquid Whole, Frozen ": {
        "amount": 3.0,
        "unit": "tablespoon",
        "calories": 70.0,
        "TotalFat": 7.5,
        "SaturatedFat": 1.5,
        "TransFat": 0.0,
        "cholesterol": 0.175,
        "sodium": 0.065,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0000012,
        "calcium": 0.029,
        "iron": 0.0001,
        "potassium": 0.135
    },
    "Chicken, Diced, Cooked, Frozen ": {
        "amount": 3.0,
        "unit": "ounce",
        "calories": 130.0,
        "TotalFat": 6.0,
        "SaturatedFat": 1.5,
        "TransFat": 0.0,
        "cholesterol": 0.075,
        "sodium": 0.09,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 19.0,
        "vitaminD": 0.0,
        "calcium": 0.01,
        "iron": 0.0005,
        "potassium": 0.39
    },
    "Chicken, Fajita Seasoned Strips, Cooked, Frozen ": {
        "amount": 3.0,
        "unit": "ounce",
        "calories": 120.0,
        "TotalFat": 5.93,
        "SaturatedFat": 1.87,
        "TransFat": 0.0,
        "cholesterol": 0.07062,
        "sodium": 0.42,
        "carbs": 1.32,
        "fiber": 0.22,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 16.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.00104,
        "potassium": 0.312
    },
    "Turkey, Taco Filling, Cooked, Frozen ": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 70.0,
        "TotalFat": 2.5,
        "SaturatedFat": 0.5,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.135,
        "carbs": 2.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 9.0,
        "vitaminD": 0.0,
        "calcium": 0.05,
        "iron": 0.0011,
        "potassium": 0.19
    },
    "Turkey, Deli Breast, Frozen ": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 60.0,
        "TotalFat": 1.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.3,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 12.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.0
    },
    "Turkey, Deli Breast, Smoked, Frozen ": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 60.0,
        "TotalFat": 1.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.3,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 12.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.0
    },
    "Turkey, Roast, Frozen ": {
        "amount": 4.0,
        "unit": "ounce",
        "calories": 120.0,
        "TotalFat": 2.0,
        "SaturatedFat": 0.5,
        "TransFat": 0.0,
        "cholesterol": 0.06,
        "sodium": 0.54,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 21.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.28
    },
    "Turkey, Deli Ham, Smoked, Frozen": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 60.0,
        "TotalFat": 2.5,
        "SaturatedFat": 1.0,
        "TransFat": 0.0,
        "cholesterol": 0.035,
        "sodium": 0.25,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 1.0,
        "protein": 9.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.28
    },
    "Beef, Canned": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 120.0,
        "TotalFat": 8.0,
        "SaturatedFat": 4.0,
        "TransFat": 0.0,
        "cholesterol": 0.035,
        "sodium": 0.15,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 10.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0007,
        "potassium": 0.0
    },
    "Beef, Crumbles w/SPP, Cooked, Frozen": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 112.05,
        "TotalFat": 6.16,
        "SaturatedFat": 2.77,
        "TransFat": 0.38,
        "cholesterol": 0.0296,
        "sodium": 0.177,
        "carbs": 1.8,
        "fiber": 0.92,
        "sugar": 0.47,
        "AddedSugars": 0.4,
        "protein": 10.83,
        "vitaminD": 0.00000005,
        "calcium": 0.02355,
        "iron": 0.0007,
        "potassium": 0.444
    },
    "Pork, Canned": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 120.0,
        "TotalFat": 8.0,
        "SaturatedFat": 3.0,
        "TransFat": 0.0,
        "cholesterol": 0.035,
        "sodium": 0.15,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 9.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.00072,
        "potassium": 0.0
    },
    "Beef, Fine Ground, 100%, 85/15, Frozen": {
        "amount": 3.5,
        "unit": "ounce",
        "calories": 210.0,
        "TotalFat": 15.0,
        "SaturatedFat": 6.0,
        "TransFat": 0.0,
        "cholesterol": 0.067,
        "sodium": 0.07,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 18.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.00195,
        "potassium": 0.0
    },
    "Beef, Patties, Lean, 2.0 MMA, Frozen": {
        "amount": 1.0,
        "unit": "patty",
        "calories": 100.0,
        "TotalFat": 3.5,
        "SaturatedFat": 1.5,
        "TransFat": 0.0,
        "cholesterol": 0.04,
        "sodium": 0.17,
        "carbs": 2.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 15.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.00036,
        "potassium": 0.0
    },
    "Ham, 97% Fat Free, Water-Added, Cooked, Frozen ": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 50.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.35,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 9.0,
        "vitaminD": 0.0,
        "calcium": 0.004,
        "iron": 0.002,
        "potassium": 0.503
    },
    "Ham, 97% Fat Free, Water-Added, Cooked, Sliced, Frozen": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 50.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.35,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 9.0,
        "vitaminD": 0.0,
        "calcium": 0.004,
        "iron": 0.002,
        "potassium": 0.503
    },
    "Tuna, Chunk Light, Canned (K)": {
        "amount": 0.33,
        "unit": "cup",
        "calories": 80.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.5,
        "TransFat": 0.0,
        "cholesterol": 0.04,
        "sodium": 0.38,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 16.0,
        "vitaminD": 0.000001,
        "calcium": 0.01,
        "iron": 0.0002,
        "potassium": 0.13
    },
    "Apple Slices, Unsweetened, Canned": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.015,
        "carbs": 14.0,
        "fiber": 2.0,
        "sugar": 12.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0002,
        "potassium": 0.0
    },
    "Mixed Fruit (Peaches, Pears, Grapes), Extra Light Syrup, Canned": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 15.0,
        "fiber": 1.0,
        "sugar": 13.0,
        "AddedSugars": 4.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.007,
        "iron": 0.0008,
        "potassium": 0.113
    },
    "Apricots, Diced, Extra Light Syrup, Canned": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.01,
        "carbs": 14.0,
        "fiber": 1.0,
        "sugar": 12.0,
        "AddedSugars": 4.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.01,
        "iron": 0.0,
        "potassium": 0.119
    },
    "Peaches, Sliced, Extra Light Syrup, Canned": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 15.0,
        "fiber": 1.0,
        "sugar": 12.0,
        "AddedSugars": 5.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.006,
        "iron": 0.0,
        "potassium": 0.105
    },
    "Peaches, Diced, Extra Light Syrup, Canned": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 14.0,
        "fiber": 1.0,
        "sugar": 13.0,
        "AddedSugars": 5.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.006,
        "iron": 0.0003,
        "potassium": 0.113
    },
    "Pears, Sliced, Extra Light Syrup, Canned": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 15.0,
        "fiber": 2.0,
        "sugar": 12.0,
        "AddedSugars": 5.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.006,
        "iron": 0.0002,
        "potassium": 0.06
    },
    "Pears, Diced, Extra Light Syrup, Canned (K)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 16.0,
        "fiber": 2.0,
        "sugar": 12.0,
        "AddedSugars": 4.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.006,
        "iron": 0.0002,
        "potassium": 0.065
    },
    "Pears, Halves, Extra Light Syrup, Canned": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 70.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 16.0,
        "fiber": 2.0,
        "sugar": 13.0,
        "AddedSugars": 7.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.008,
        "iron": 0.0,
        "potassium": 0.076
    },
    "Peaches, Sliced, Frozen ": {
        "amount": 1.0,
        "unit": "cup",
        "calories": 80.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 19.0,
        "fiber": 2.0,
        "sugar": 14.0,
        "AddedSugars": 9.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.27
    },
    "Peaches, Diced, Cups, Frozen ": {
        "amount": 4.4,
        "unit": "ounce",
        "calories": 90.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 21.0,
        "fiber": 2.0,
        "sugar": 19.0,
        "AddedSugars": 10.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.22
    },
    "Blueberries, Wild, Unsweetened, Frozen ": {
        "amount": 1.0,
        "unit": "cup",
        "calories": 80.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 19.0,
        "fiber": 6.0,
        "sugar": 10.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.025,
        "iron": 0.0,
        "potassium": 0.095
    },
    "Strawberries, Sliced, Frozen": {
        "amount": 0.25,
        "unit": "cup",
        "calories": 45.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.001,
        "carbs": 11.0,
        "fiber": 1.0,
        "sugar": 9.0,
        "AddedSugars": 6.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.01,
        "iron": 0.0,
        "potassium": 0.095
    },
    "Strawberries, Diced, Cups, Frozen": {
        "amount": 4.5,
        "unit": "ounce",
        "calories": 80.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 19.0,
        "fiber": 2.0,
        "sugar": 16.0,
        "AddedSugars": 10.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.0008,
        "potassium": 0.18
    },
    "Apricots, Diced, Cups, Frozen ": {
        "amount": 4.5,
        "unit": "ounce",
        "calories": 110.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 25.0,
        "fiber": 2.0,
        "sugar": 23.0,
        "AddedSugars": 13.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0004,
        "potassium": 0.28
    },
    "Orange Juice, Unsweetened, Cartons, Frozen ": {
        "amount": 4.0,
        "unit": "ounce",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 14.0,
        "fiber": 0.0,
        "sugar": 12.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0004,
        "potassium": 0.24
    },
    "Raisins, Unsweetened, Individual Portion": {
        "amount": 1.33,
        "unit": "ounce",
        "calories": 110.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.01,
        "carbs": 30.0,
        "fiber": 2.0,
        "sugar": 25.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.023,
        "iron": 0.0004,
        "potassium": 0.28
    },
    "Beans, Green, Low-sodium, Canned (K)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 20.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 4.0,
        "fiber": 1.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.0,
        "potassium": 0.0
    },
    "Carrots, Sliced, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 30.0,
        "TotalFat": 0.12,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.11,
        "carbs": 6.0,
        "fiber": 2.004,
        "sugar": 3.996,
        "AddedSugars": 0.0,
        "protein": 0.996,
        "vitaminD": 0.0,
        "calcium": 0.03,
        "iron": 0.001,
        "potassium": 0.145
    },
    "Corn, Whole Kernel, No Salt Added, Canned (K)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 70.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.015,
        "carbs": 14.0,
        "fiber": 2.0,
        "sugar": 5.0,
        "AddedSugars": 0.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0008,
        "potassium": 0.21
    },
    "Peas, Green, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 70.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.08,
        "carbs": 14.0,
        "fiber": 5.0,
        "sugar": 7.0,
        "AddedSugars": 2.0,
        "protein": 4.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.0,
        "potassium": 0.19
    },
    "Sweet Potatoes, Light Syrup, No Salt Added, Canned ": {
        "amount": 0.66,
        "unit": "cup",
        "calories": 130.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 34.0,
        "fiber": 4.0,
        "sugar": 22.0,
        "AddedSugars": 11.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.016,
        "iron": 0.0004,
        "potassium": 0.366
    },
    "Tomato Paste, No Salt Added, Canned ": {
        "amount": 2.0,
        "unit": "tablespoon",
        "calories": 30.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.02,
        "carbs": 6.0,
        "fiber": 1.0,
        "sugar": 4.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0012,
        "potassium": 0.27
    },
    "Tomatoes, Diced, No Salt Added, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 25.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.015,
        "carbs": 5.0,
        "fiber": 1.0,
        "sugar": 3.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.23
    },
    "Salsa, Low-sodium, Canned ": {
        "amount": 2.0,
        "unit": "tablespoon",
        "calories": 15.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.035,
        "carbs": 3.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.09
    },
    "Tomato Sauce, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 45.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.075,
        "carbs": 9.0,
        "fiber": 2.0,
        "sugar": 7.0,
        "AddedSugars": 3.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.015,
        "iron": 0.0,
        "potassium": 0.29
    },
    "Spaghetti Sauce, Low-sodium, Canned": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 45.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 9.0,
        "fiber": 2.0,
        "sugar": 6.0,
        "AddedSugars": 2.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.016,
        "iron": 0.0,
        "potassium": 0.302
    },
    "Corn, Whole Kernel, No Salt Added, Frozen ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 70.0,
        "TotalFat": 0.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 18.0,
        "fiber": 2.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 3.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.18
    },
    "Peas, Green, No Salt Added, Frozen ": {
        "amount": 0.66,
        "unit": "cup",
        "calories": 70.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 12.0,
        "fiber": 4.0,
        "sugar": 4.0,
        "AddedSugars": 0.0,
        "protein": 4.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.0
    },
    "Beans, Green, No Salt Added, Frozen ": {
        "amount": 0.75,
        "unit": "cup",
        "calories": 35.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 6.0,
        "fiber": 2.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.04,
        "iron": 0.0007,
        "potassium": 0.16
    },
    "Carrots, Sliced, No Salt Added, Frozen ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 30.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.04,
        "carbs": 6.0,
        "fiber": 3.0,
        "sugar": 4.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.000021,
        "calcium": 0.03,
        "iron": 0.0014,
        "potassium": 0.16
    },
    "Potatoes, Wedges, Fat Free, Low-sodium, Frozen (IQF)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 70.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.01,
        "carbs": 16.0,
        "fiber": 1.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.007,
        "iron": 0.0,
        "potassium": 0.436
    },
    "Beans, Black, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 100.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 18.0,
        "fiber": 6.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 7.0,
        "vitaminD": 0.0,
        "calcium": 0.038,
        "iron": 0.001,
        "potassium": 0.0
    },
    "Beans, Garbanzo, Low-sodium, Canned (K)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 110.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 18.0,
        "fiber": 6.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.001,
        "potassium": 0.31
    },
    "Beans, Refried, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 140.0,
        "TotalFat": 1.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 24.0,
        "fiber": 6.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 8.0,
        "vitaminD": 0.0,
        "calcium": 0.05,
        "iron": 0.00239,
        "potassium": 0.54
    },
    "Beans, Vegetarian, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 140.0,
        "TotalFat": 0.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.125,
        "carbs": 27.0,
        "fiber": 7.0,
        "sugar": 5.0,
        "AddedSugars": 4.0,
        "protein": 8.0,
        "vitaminD": 0.0,
        "calcium": 0.06,
        "iron": 0.0011,
        "potassium": 0.45
    },
    "Beans, Pinto, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 100.0,
        "TotalFat": 0.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 17.0,
        "fiber": 9.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 5.0,
        "vitaminD": 0.0,
        "calcium": 0.067,
        "iron": 0.002,
        "potassium": 0.0
    },
    "Beans, Small Red, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 110.0,
        "TotalFat": 0.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 20.0,
        "fiber": 9.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0,
        "calcium": 0.05,
        "iron": 0.002,
        "potassium": 0.4
    },
    "Beans, Black-eyed Pea, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 100.0,
        "TotalFat": 0.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 18.0,
        "fiber": 3.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0,
        "calcium": 0.043,
        "iron": 0.00132,
        "potassium": 0.0
    },
    "Beans, Pink, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 80.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 16.0,
        "fiber": 7.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 5.0,
        "vitaminD": 0.0,
        "calcium": 0.05,
        "iron": 0.001,
        "potassium": 0.35
    },
    "Beans, Kidney, Dark Red, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 100.0,
        "TotalFat": 0.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 20.0,
        "fiber": 6.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 8.0,
        "vitaminD": 0.0,
        "calcium": 0.052,
        "iron": 0.00185,
        "potassium": 0.0
    },
    "Beans, Great Northern, Low-sodium, Canned ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 130.0,
        "TotalFat": 7.0,
        "SaturatedFat": 1.5,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.14,
        "carbs": 12.0,
        "fiber": 6.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 7.0,
        "vitaminD": 0.0,
        "calcium": 0.09,
        "iron": 0.001,
        "potassium": 0.0
    },
    "Beans, Pinto, Dry": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 225.0,
        "TotalFat": 1.72,
        "SaturatedFat": 0.3,
        "TransFat": 0.1,
        "cholesterol": 0.0,
        "sodium": 0.013,
        "carbs": 62.43,
        "fiber": 32.8,
        "sugar": 1.14,
        "AddedSugars": 0.1,
        "protein": 19.3,
        "vitaminD": 0.0000001,
        "calcium": 0.035,
        "iron": 0.00176,
        "potassium": 1.47
    },
    "Peanut Butter, Smooth": {
        "amount": 2.0,
        "unit": "tablespoon",
        "calories": 180.0,
        "TotalFat": 15.0,
        "SaturatedFat": 2.5,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.125,
        "carbs": 8.0,
        "fiber": 2.0,
        "sugar": 3.0,
        "AddedSugars": 2.0,
        "protein": 7.0,
        "vitaminD": 0.0,
        "calcium": 0.019,
        "iron": 0.00185,
        "potassium": 0.184
    },
    "Pasta, Spaghetti, Enriched": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 200.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 41.0,
        "fiber": 1.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 7.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.00531,
        "potassium": 0.0
    },
    "Oats, Rolled, Quick Cooking": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 150.0,
        "TotalFat": 2.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 27.0,
        "fiber": 4.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 5.0,
        "vitaminD": 0.0,
        "calcium": 0.018,
        "iron": 0.0005,
        "potassium": 0.127
    },
    "Rice, Long Grain, Parboiled": {
        "amount": 0.25,
        "unit": "cup",
        "calories": 170.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 37.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 4.0,
        "vitaminD": 0.0,
        "calcium": 0.05,
        "iron": 0.01,
        "potassium": 0.07
    },
    "Rice, Brown, Long Grain, Parboiled": {
        "amount": 0.25,
        "unit": "cup",
        "calories": 150.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 32.0,
        "fiber": 1.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 3.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0014,
        "potassium": 0.11
    },
    "Chicken, Oven Roasted, Cut-up, Cooked, Frozen ": {
        "amount": 3.0,
        "unit": "ounce",
        "calories": 160.0,
        "TotalFat": 9.0,
        "SaturatedFat": 3.0,
        "TransFat": 0.0,
        "cholesterol": 0.095,
        "sodium": 0.15,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 18.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.0014,
        "potassium": 0.26
    },
    "Spaghetti Sauce, Low-sodium, Pouch": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 35.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.065,
        "carbs": 8.0,
        "fiber": 2.0,
        "sugar": 5.0,
        "AddedSugars": 0.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.019,
        "iron": 0.0005,
        "potassium": 0.422
    },
    "Salsa, Low-sodium, Pouch ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 35.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.07,
        "carbs": 8.0,
        "fiber": 2.0,
        "sugar": 5.0,
        "AddedSugars": 0.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.018,
        "iron": 0.0007,
        "potassium": 0.416
    },
    "Tomato Sauce, Low-sodium, Pouch ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 40.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.1,
        "carbs": 9.0,
        "fiber": 2.0,
        "sugar": 6.0,
        "AddedSugars": 0.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.019,
        "iron": 0.001,
        "potassium": 0.482
    },
    "Flour, White Whole Wheat/Enriched 60/40 Blend": {
        "amount": 0.25,
        "unit": "cup",
        "calories": 100.0,
        "TotalFat": 0.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 23.0,
        "fiber": 4.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 3.0,
        "vitaminD": 0.0,
        "calcium": 0.01,
        "iron": 0.001,
        "potassium": 0.084
    },
    "Beef, Fine Ground, 100%, 85/15, LFTB OPT, Frozen": {
        "amount": 4.0,
        "unit": "ounce",
        "calories": 240.0,
        "TotalFat": 17.0,
        "SaturatedFat": 7.0,
        "TransFat": 0.0,
        "cholesterol": 0.07,
        "sodium": 0.065,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 22.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.001,
        "potassium": 0.0
    },
    "Beef, Patties w/SPP, Cooked, 2.0 MMA, Frozen ": {
        "amount": 2.2,
        "unit": "ounce",
        "calories": 120.0,
        "TotalFat": 8.0,
        "SaturatedFat": 3.0,
        "TransFat": 0.0,
        "cholesterol": 0.03,
        "sodium": 0.21,
        "carbs": 1.0,
        "fiber": 1.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 11.0,
        "vitaminD": 0.0,
        "calcium": 0.017,
        "iron": 0.001,
        "potassium": 0.598
    },
    "Beef, Patties, 100%, 90/10, 2.0 MMA, Frozen": {
        "amount": 1.0,
        "unit": "patty",
        "calories": 130.0,
        "TotalFat": 8.0,
        "SaturatedFat": 3.0,
        "TransFat": 0.0,
        "cholesterol": 0.05,
        "sodium": 0.05,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 15.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.001,
        "potassium": 0.0
    },
    "Beef, Patties, 100%, 85/15, 2.0 MMA, Frozen": {
        "amount": 1.0,
        "unit": "patty",
        "calories": 180.0,
        "TotalFat": 12.0,
        "SaturatedFat": 5.0,
        "TransFat": 0.0,
        "cholesterol": 0.045,
        "sodium": 0.045,
        "carbs": 3.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 12.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.001,
        "potassium": 0.0
    },
    "Applesauce, Unsweetened, Cups, Shelf-Stable": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 50.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 14.0,
        "fiber": 1.0,
        "sugar": 12.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.005,
        "iron": 0.002,
        "potassium": 0.0
    },
    "Pancakes, Whole Grain or Whole Grain-Rich, Frozen": {
        "amount": 2.4,
        "unit": "ounce",
        "calories": 130.0,
        "TotalFat": 2.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.005,
        "sodium": 0.25,
        "carbs": 26.0,
        "fiber": 3.0,
        "sugar": 6.0,
        "AddedSugars": 6.0,
        "protein": 4.0,
        "vitaminD": 0.0,
        "calcium": 0.063,
        "iron": 0.002,
        "potassium": 0.111
    },
    "Tortillas, Whole Grain or Whole Grain-Rich, 8 inch, Frozen": {
        "amount": 1.0,
        "unit": "tortilla",
        "calories": 110.0,
        "TotalFat": 3.0,
        "SaturatedFat": 1.5,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.16,
        "carbs": 18.0,
        "fiber": 3.0,
        "sugar": 1.0,
        "AddedSugars": 1.0,
        "protein": 3.0,
        "vitaminD": 0.0,
        "calcium": 0.038,
        "iron": 0.0,
        "potassium": 0.152
    },
    "Cheese, Mozzarella, Low Moisture Part Skim, String, Chilled": {
        "amount": 1.0,
        "unit": "piece",
        "calories": 90.0,
        "TotalFat": 6.0,
        "SaturatedFat": 3.0,
        "TransFat": 0.0,
        "cholesterol": 0.015,
        "sodium": 0.19,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 7.0,
        "vitaminD": 0.0000001,
        "calcium": 0.21,
        "iron": 0.001,
        "potassium": 0.05
    },
    "Yogurt, High-Protein, Blueberry, Chilled (K)": {
        "amount": 4.0,
        "unit": "ounce",
        "calories": 90.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.005,
        "sodium": 0.045,
        "carbs": 13.0,
        "fiber": 1.0,
        "sugar": 12.0,
        "AddedSugars": 8.0,
        "protein": 9.0,
        "vitaminD": 0.0,
        "calcium": 0.11,
        "iron": 0.001,
        "potassium": 0.14
    },
    "Yogurt, High-Protein, Strawberry, Chilled (K)": {
        "amount": 4.0,
        "unit": "ounce",
        "calories": 90.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.005,
        "sodium": 0.07,
        "carbs": 12.0,
        "fiber": 1.0,
        "sugar": 11.0,
        "AddedSugars": 8.0,
        "protein": 9.0,
        "vitaminD": 0.0,
        "calcium": 0.11,
        "iron": 0.0001,
        "potassium": 0.14
    },
    "Yogurt, High-Protein, Vanilla, Chilled (K)": {
        "amount": 0.75,
        "unit": "cup",
        "calories": 130.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.01,
        "sodium": 0.06,
        "carbs": 17.0,
        "fiber": 0.0,
        "sugar": 16.0,
        "AddedSugars": 11.0,
        "protein": 14.0,
        "vitaminD": 0.0,
        "calcium": 0.17,
        "iron": 0.0,
        "potassium": 0.22
    },
    "Chicken, Unseasoned Grilled Strips, Cooked, Frozen": {
        "amount": 3.0,
        "unit": "ounce",
        "calories": 100.0,
        "TotalFat": 4.14,
        "SaturatedFat": 1.0,
        "TransFat": 0.0,
        "cholesterol": 0.065,
        "sodium": 0.14,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 17.0,
        "vitaminD": 0.00000007,
        "calcium": 0.00517,
        "iron": 0.0,
        "potassium": 0.282
    },
    "Broccoli Florets, No Salt Added, Frozen": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 10.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.01,
        "carbs": 2.0,
        "fiber": 1.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.0,
        "potassium": 0.09
    },
    "Carrots, Diced, No Salt Added, Frozen": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 26.34,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.045,
        "carbs": 6.0,
        "fiber": 2.0,
        "sugar": 3.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.0002,
        "potassium": 0.21
    },
    "Pasta, Macaroni, Whole Grain-Rich Blend": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 210.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 41.0,
        "fiber": 3.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 8.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0005,
        "potassium": 0.0
    },
    "Pasta, Rotini, Whole Grain-Rich Blend": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 200.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 40.0,
        "fiber": 3.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 7.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0003,
        "potassium": 0.0
    },
    "Pasta, Spaghetti, Whole Grain-Rich Blend": {
        "amount": 2.0,
        "unit": "ounce",
        "calories": 210.0,
        "TotalFat": 1.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 41.0,
        "fiber": 3.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 8.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.01,
        "potassium": 0.0
    },
    "Applesauce, Unsweetened, Canned (K)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 70.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 16.0,
        "fiber": 1.0,
        "sugar": 13.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.004,
        "iron": 0.01,
        "potassium": 0.124
    },
    "Turkey, Deli Breast, Sliced, Frozen": {
        "amount": 2.0,
        "unit": "slices",
        "calories": 60.0,
        "TotalFat": 1.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.27,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 12.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.01,
        "potassium": 0.38
    },
    "Sweet Potatoes, Cubes, No Salt Added, Frozen": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 45.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.015,
        "carbs": 10.0,
        "fiber": 1.0,
        "sugar": 3.2,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.015,
        "iron": 0.001,
        "potassium": 0.365
    },
    "Blueberries, Unsweetened, Frozen ": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 35.0,
        "TotalFat": 0.6,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 9.0,
        "fiber": 2.0,
        "sugar": 6.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.01,
        "iron": 0.0004,
        "potassium": 0.04
    },
    "Orange Juice, Unsweetened, Cups, Frozen": {
        "amount": 4.0,
        "unit": "ounce",
        "calories": 60.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 14.0,
        "fiber": 0.0,
        "sugar": 12.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0004,
        "potassium": 0.24
    },
    "Beef, Patties, Cooked, 2.0 MMA, Frozen": {
        "amount": 1.0,
        "unit": "patty",
        "calories": 120.0,
        "TotalFat": 7.0,
        "SaturatedFat": 2.5,
        "TransFat": 0.0,
        "cholesterol": 0.045,
        "sodium": 0.15,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 13.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0001,
        "potassium": 0.0
    },
    "Sweet Potatoes, Crinkle Cut Fries, Low-Sodium, Frozen": {
        "amount": 1.0,
        "unit": "cup",
        "calories": 130.0,
        "TotalFat": 5.0,
        "SaturatedFat": 1.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.1,
        "carbs": 19.0,
        "fiber": 4.0,
        "sugar": 7.0,
        "AddedSugars": 0.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.019,
        "iron": 0.0,
        "potassium": 0.35
    },
    "Cranberries, Dried, Individual Portion": {
        "amount": 1.16,
        "unit": "ounce",
        "calories": 110.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 27.0,
        "fiber": 2.0,
        "sugar": 24.0,
        "AddedSugars": 21.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.0
    },
    "Pepper/Onion Strips, No Salt Added, Frozen": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 25.0,
        "TotalFat": 0.1,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.002,
        "carbs": 6.0,
        "fiber": 0.7,
        "sugar": 3.0,
        "AddedSugars": 0.0,
        "protein": 1.0,
        "vitaminD": 0.0,
        "calcium": 0.012,
        "iron": 0.0,
        "potassium": 0.09
    },
    "Pork, Pulled, Cooked, Frozen": {
        "amount": 3.5,
        "unit": "ounce",
        "calories": 150.0,
        "TotalFat": 8.7,
        "SaturatedFat": 3.5,
        "TransFat": 0.0,
        "cholesterol": 0.055,
        "sodium": 0.32,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 1.0,
        "protein": 18.0,
        "vitaminD": 0.0,
        "calcium": 0.006,
        "iron": 0.0004,
        "potassium": 0.398
    },
    "Potatoes, Diced, No Salt Added, Frozen": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 25.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 5.0,
        "fiber": 2.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 0.3,
        "vitaminD": 0.0,
        "calcium": 0.154,
        "iron": 0.0,
        "potassium": 0.113
    },
    "Strawberries, Whole, Unsweetened, Frozen (IQF)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 25.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.001,
        "carbs": 6.0,
        "fiber": 1.0,
        "sugar": 3.0,
        "AddedSugars": 0.0,
        "protein": 0.3,
        "vitaminD": 0.0,
        "calcium": 0.01,
        "iron": 0.002,
        "potassium": 0.1
    },
    "Alaska Pollock, Whole Grain-Rich Breaded Sticks, Frozen": {
        "amount": 4.0,
        "unit": "pieces",
        "calories": 220.0,
        "TotalFat": 9.0,
        "SaturatedFat": 1.0,
        "TransFat": 0.0,
        "cholesterol": 0.045,
        "sodium": 0.32,
        "carbs": 20.0,
        "fiber": 2.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 16.0,
        "vitaminD": 0.0000001,
        "calcium": 0.02,
        "iron": 0.0011,
        "potassium": 0.32
    },
    "Peanut Butter, Individual Portion, Smooth": {
        "amount": 1.1,
        "unit": "ounce",
        "calories": 180.0,
        "TotalFat": 15.0,
        "SaturatedFat": 2.5,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.125,
        "carbs": 8.0,
        "fiber": 2.0,
        "sugar": 3.0,
        "AddedSugars": 2.0,
        "protein": 7.0,
        "vitaminD": 0.0,
        "calcium": 0.018,
        "iron": 0.0,
        "potassium": 0.179
    },
    "Flour, 100% White Whole Wheat": {
        "amount": 0.25,
        "unit": "cup",
        "calories": 110.0,
        "TotalFat": 0.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 22.0,
        "fiber": 4.0,
        "sugar": 1.0,
        "AddedSugars": 0.0,
        "protein": 4.0,
        "vitaminD": 0.0,
        "calcium": 0.012,
        "iron": 0.00081,
        "potassium": 0.111
    },
    "Mixed Berries (Blueberries, Strawberries), Cups, Frozen": {
        "amount": 4.0,
        "unit": "ounce",
        "calories": 70.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.005,
        "carbs": 16.0,
        "fiber": 2.0,
        "sugar": 13.0,
        "AddedSugars": 7.0,
        "protein": 0.0,
        "vitaminD": 0.000049,
        "calcium": 0.014,
        "iron": 0.0005,
        "potassium": 0.107
    },
    "Strawberries, Sliced, Unsweetened, Frozen (IQF)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 25.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 6.0,
        "fiber": 1.0,
        "sugar": 3.0,
        "AddedSugars": 0.0,
        "protein": 0.3,
        "vitaminD": 0.0,
        "calcium": 0.01,
        "iron": 0.0052,
        "potassium": 0.1
    },
    "Cherries, Sweet, Pitted, Unsweetened, Frozen (IQF)": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 45.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 11.0,
        "fiber": 1.0,
        "sugar": 9.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.00988,
        "iron": 0.0004,
        "potassium": 0.0
    },
    "Turkey, Deli Breast, Smoked, Sliced, Frozen": {
        "amount": 2.0,
        "unit": "slices",
        "calories": 60.0,
        "TotalFat": 1.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.25,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 12.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.001,
        "potassium": 0.28
    },
    "Turkey, Deli Ham, Smoked, Sliced, Frozen": {
        "amount": 2.0,
        "unit": "slices",
        "calories": 60.0,
        "TotalFat": 2.5,
        "SaturatedFat": 1.0,
        "TransFat": 0.0,
        "cholesterol": 0.035,
        "sodium": 0.25,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 1.0,
        "AddedSugars": 1.0,
        "protein": 9.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.28
    },
    "Chicken, Grilled Fillet, 2.0 MMA, Cooked, Frozen": {
        "amount": 1.0,
        "unit": "fillet",
        "calories": 90.0,
        "TotalFat": 1.5,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.06,
        "sodium": 0.19,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 18.0,
        "vitaminD": 0.0,
        "calcium": 0.004,
        "iron": 0.0005,
        "potassium": 0.404
    },
    "Cheese, Cheddar, Yellow, Sliced, Chilled": {
        "amount": 1.0,
        "unit": "slice",
        "calories": 90.0,
        "TotalFat": 7.0,
        "SaturatedFat": 4.0,
        "TransFat": 0.0,
        "cholesterol": 0.02,
        "sodium": 0.14,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 5.0,
        "vitaminD": 0.0000001,
        "calcium": 0.15,
        "iron": 0.0,
        "potassium": 0.02
    },
    "Cheese, Pepper Jack, Shredded, Chilled": {
        "amount": 0.25,
        "unit": "cup",
        "calories": 100.0,
        "TotalFat": 8.0,
        "SaturatedFat": 4.0,
        "TransFat": 0.0,
        "cholesterol": 0.025,
        "sodium": 0.16,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 6.0,
        "vitaminD": 0.0,
        "calcium": 0.19,
        "iron": 0.0003,
        "potassium": 0.015
    },
    "Mixed Vegetables, No Salt Added, Frozen": {
        "amount": 0.5,
        "unit": "cup",
        "calories": 50.0,
        "TotalFat": 0.1,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.035,
        "carbs": 9.0,
        "fiber": 1.0,
        "sugar": 2.0,
        "AddedSugars": 0.0,
        "protein": 2.0,
        "vitaminD": 0.0,
        "calcium": 0.02,
        "iron": 0.0004,
        "potassium": 0.03
    },
    "Chicken, Cut-up, Frozen ": {
        "amount": 4.0,
        "unit": "ounce",
        "calories": 250.0,
        "TotalFat": 19.0,
        "SaturatedFat": 6.0,
        "TransFat": 0.0,
        "cholesterol": 0.065,
        "sodium": 0.07,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 19.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0007,
        "potassium": 0.0
    },
    "Eggs, Patties, Cooked, 1.0 MMA, Round, Frozen": {
        "amount": 1.25,
        "unit": "ounce",
        "calories": 50.0,
        "TotalFat": 3.0,
        "SaturatedFat": 1.0,
        "TransFat": 0.0,
        "cholesterol": 0.125,
        "sodium": 0.05,
        "carbs": 1.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 4.0,
        "vitaminD": 0.000001,
        "calcium": 0.019,
        "iron": 0.0,
        "potassium": 0.047
    },
    "": {
        "amount": 0.0,
        "unit": "",
        "calories": 0.0,
        "TotalFat": 0.0,
        "SaturatedFat": 0.0,
        "TransFat": 0.0,
        "cholesterol": 0.0,
        "sodium": 0.0,
        "carbs": 0.0,
        "fiber": 0.0,
        "sugar": 0.0,
        "AddedSugars": 0.0,
        "protein": 0.0,
        "vitaminD": 0.0,
        "calcium": 0.0,
        "iron": 0.0,
        "potassium": 0.0
    }



  /// new ingredients


};

function sortIngredientsByKeys(ingredients) {
  // Extract the keys and sort them
  const sortedKeys = Object.keys(ingredients).sort();

  // Create a new object with sorted keys
  const sortedIngredients = {};
  sortedKeys.forEach(key => {
    sortedIngredients[key] = ingredients[key];
  });

  return sortedIngredients;
}

export default sortIngredientsByKeys(loadIngredients);
